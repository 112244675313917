import moment from "moment";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useForm, Controller } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import { useMutation } from "react-query";
import axios from "axios";
import { useLocation, useNavigate } from "react-router";
import PopupModal from "../popupmodal/PopupModal";
export default function StudentCreationForm() {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const { state } = useLocation();

  const [isOpen, setIsOpen] = useState(false);
  const [popupState, setPopupState] = useState({
    addData: false,
  });

  // Function to close the modal
  const closeModal = () => {
    setIsOpen(false);
    setPopupState({
      addData: false,
    });
  };

  const handleNavigate = () => {
    navigate("/StudentViewTable");
    setIsOpen(false);
    setPopupState({
      addData: false,
    });
  };

  useEffect(() => {
    if (state?.length > 0) {
      state?.forEach((ele) => {
        Object.entries(ele || {}).forEach(([key, value]) => {
          setValue(key, value || "");
        });
      });
    }
  }, [state, setValue]);

  const update = useMutation(
    async (postData) => {
      try {
        // Making the API call using axios
        const response = await axios.put(
          `https://api.2kvirtualworld.com/EMS/students/updateStudent/${state[0]?.registerNumber}`,
          postData
        );
        return response?.data;
      } catch (error) {
        console.error("Error occurred while updating the student:", error);
        throw error; // Rethrow the error to trigger the onError handler
      }
    },
    {
      onSuccess: (data) => {
        console.log("User updated successfully:", data);
        // navigate("/StudentViewTable");
        setIsOpen(true);
        setPopupState({
          addData: true,
        });
      },
      onError: (error) => {
        console.error("Mutation failed:", error);
      },
    }
  );

  const { mutate, isLoading, isError, isSuccess } = useMutation(
    async (postData) => {
      try {
        const response = await axios.post(
          `https://api.2kvirtualworld.com/EMS/students/postStudent`,
          postData
        );
        return response;
      } catch (error) {
        if (error?.response?.status === 500) {
          alert(
            "Oops! Something went wrong on our end. Please try again later."
          );
        } else if (error?.response) {
          const { data } = error?.response;
          console.log("Error Response:", data);

          // setErrorMessage(data?.message || "An error occurred");
        } else {
          console.log("Unexpected Error:", error?.message);
          alert("An unexpected error occurred. Please try again.");
        }

        throw error;
      }
    },
    {
      onSuccess: () => {
        console.log("Data added successfully...");
        setIsOpen(true);
        setPopupState({
          addData: true,
        });

        // setRegisterPopup(1);
      },
      onError: (error) => {
        console.log("Error occurred in mutation:", error);
      },
    }
  );

  const tdata = {
    feesamount: [10000, 10000],
    amountreceived: [1000, 5000],
    balancepending: [9000, 4000],
    dateOfPay: ["2024-12-10", "2024-11-11"],
    status: ["Pending", "Pending"],
  };
  let balance = 0;
  const onSubmit = (data) => {
    let formData = {
      registerNumber: data?.registerNumber,
      aadharNumber: data?.aadharNumber,
      studentName: data?.studentName,
      collegeName: data?.collegeName,
      course: data?.course,
      emergencyNumber: data?.emergencyNumber,
      mobileNumber: data?.emergencyNumber,
      dob: moment(data?.dob).format("YYYY-MM-DD"),
      area: data?.area,
      city: data?.city,
      pincode: data?.pincode,
      flatNo: data?.flatNo,
      street: data?.street,
      joiningDate: moment(data?.joiningDate).format("YYYY-MM-DD"),
      relievingDate: moment(data?.relievingDate).format("YYYY-MM-DD"),
    };
    if (state && state[0]?.registerNumber) {
      update.mutate(formData);
    } else {
      mutate(formData);
    }
  };

  return (
    <>
      <div className="studentAddMainContainer mx-3 mt-3 pt-2 pb-4">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div
            style={{
              display: "flex",
              direction: "row",
              justifyContent: "center",
              gap: "200px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                paddingTop: "15px",
                gap: "15px",
              }}
            >
              <label className="stulabel">StudentName</label>

              <input
                type="text"
                placeholder="Deepa"
                className="stuinput"
                {...register("studentName")}
              />

              <label className="stulabel"> CollegeName/company name</label>
              <input
                type="text"
                placeholder="Anand Instiute of Higher Technology"
                className="stuinput"
                {...register("collegeName")}
              />

              <label className="stulabel">phone number</label>
              <input
                type="tele"
                placeholder="7397356487"
                className="stuinput"
                {...register("mobileNumber", {
                  required: "phone number is required",
                  pattern: {
                    // value: /^[0-9]{10}$/,
                    message: "invalid",
                  },
                })}
              />
              {errors.mobileNumber && <p>Phone invalid </p>}
              <label className="stulabel">Date of Birth</label>

              <Controller
                name="dob"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    selected={field.value ? moment(field.value).toDate() : null}
                    onChange={(date) =>
                      field.onChange(moment(date).format("YYYY-MM-DD"))
                    }
                    dateFormat="yyyy/MM/dd"
                    placeholderText="30-12-2002"
                    showYearDropdown
                    className="stuinput"
                  />
                )}
              />
              <label className="stulabel"> Flat No</label>
              <input
                type="text"
                placeholder="NO:8"
                className="stuinput"
                {...register("flatNo")}
              />
              <label className="stulabel"> Area/Village </label>
              <input
                type="text"
                placeholder="Thiruppour"
                className="stuinput"
                {...register("area")}
              />
              <label className="stulabel">pincode </label>
              <input
                type="text"
                placeholder="603110"
                className="stuinput"
                {...register("pincode")}
              />
              <label className="stulabel"> Date of end</label>

              <Controller
                name="relievingDate"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    selected={field.value ? moment(field.value).toDate() : null}
                    onChange={(date) =>
                      field.onChange(moment(date).format("YYYY-MM-DD"))
                    }
                    dateFormat="yyyy/MM/dd"
                    placeholderText="30-12-2002"
                    showYearDropdown
                    className="stuinput"
                  />
                )}
              />
              <label className="stulabel">Deposit Amount</label>
              <input
                type="text"
                placeholder="0.00"
                className="stuinput"
                {...register("depositAmount")}
              />
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  paddingTop: "15px",
                  gap: "15px",
                }}
              >
                <label className="stulabel">Registernumber</label>
                <input
                  type="text"
                  placeholder="310121104020"
                  className="stuinput"
                  {...register("registerNumber")}
                />
                <label className="stulabel">Course</label>
                <input
                  type="text"
                  placeholder="React js"
                  className="stuinput"
                  {...register("course")}
                />
                <label className="stulabel">Emergency number</label>
                <input
                  type="tele"
                  placeholder="8682894103"
                  className="stuinput"
                  {...register("emergencyNumber")}
                />
                <label className="stulabel"> Aadhaar number</label>
                <input
                  type="text"
                  placeholder="7808 6561 7310"
                  className="stuinput"
                  {...register("aadharNumber")}
                />
                <label className="stulabel">street</label>
                <input
                  type="text"
                  placeholder="kacheri lane street"
                  className="stuinput"
                  {...register("street")}
                />
                <label className="stulabel">city</label>
                <input
                  type="text"
                  placeholder="chennai"
                  className="stuinput"
                  {...register("city")}
                />
                <label className="stulabel"> Date of joining </label>

                <Controller
                  name="joiningDate"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      selected={
                        field.value ? moment(field.value).toDate() : null
                      }
                      onChange={(date) =>
                        field.onChange(moment(date).format("YYYY-MM-DD"))
                      }
                      dateFormat="yyyy/MM/dd"
                      placeholderText="30-12-2002"
                      showYearDropdown
                      className="stuinput"
                    />
                  )}
                />
                <label className="stulabel">Fees Amount</label>
                <input
                  type="text"
                  placeholder="0.00"
                  className="stuinput"
                  {...register("feesAmount")}
                />
              </div>
            </div>
          </div>
          {state && state && (
            <>
              <div className="px-5 mt-3">
                <h5 className="student-details">Payment Details:</h5>
                <div className="mt-4 mb-5">
                  <table style={{ width: "100%" }}>
                    <thead>
                      <tr>
                        <th className="view-customer-table-head">
                          Fees Amount
                        </th>
                        <th className="view-customer-table-head">
                          Amount Received
                        </th>
                        <th className="view-customer-table-head">
                          Balance Pending
                        </th>
                        <th className="view-customer-table-head">
                          Date Of Pay
                        </th>
                        <th className="view-customer-table-head">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tdata?.feesamount?.map((ele, i) => {
                        balance = Number(ele) - Number(tdata?.amountreceived[i]);
                        return (
                          <tr key={i}>
                            <td className="view-customer-table-data">{ele}</td>
                            <td className="view-customer-table-data">
                              {tdata.amountreceived[i]}
                            </td>
                            <td className="view-customer-table-data">
                              {tdata.balancepending[i]}
                            </td>
                            <td className="view-customer-table-data">
                              {tdata.dateOfPay[i]}
                            </td>
                            <td className="view-customer-table-data">
                              {tdata.status[i]}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="row my-2">
                  <div className="col-6">
                    <label className="stulabel">Payment Amount</label>
                    <input
                      type="text"
                      placeholder="0.00"
                      className="stuinput"
                      {...register("payAmount")}
                    />
                  </div>
                  <div
                    className="col-6 d-flex flex-column"
                    //
                  >
                    <div>
                      <label className="stulabel text-start">Date Of Pay</label>
                    </div>
                    <Controller
                      name="dateOfPay"
                      control={control}
                      render={({ field }) => (
                        <DatePicker
                          {...field}
                          selected={
                            field.value ? moment(field.value).toDate() : null
                          }
                          onChange={(date) =>
                            field.onChange(moment(date).format("YYYY-MM-DD"))
                          }
                          dateFormat="yyyy/MM/dd"
                          placeholderText="30-12-2002"
                          showYearDropdown
                          className="stuinput"
                        />
                      )}
                    />
                  </div>
                </div>
                {/* <div className="row my-2">
                  <div className="col-6">
                    <label className="stulabel">Payment Type</label>
                    <select className="stuinput" {...register("paymentType")}>
                      <option value=""> Select Payment</option>
                      <option value="Completed">Completed</option>
                      <option value="Pending">Pending</option>
                    </select>
                  </div>
                </div> */}
              </div>
            </>
          )}
          <div className="submitbutton d-flex justify-content-end px-5 mt-3">
            <button className="stsubmit" type="submit">
              {" "}
              {isLoading ? <i class="fa fa-spinner fa-spin"></i> : "Submit"}
            </button>
          </div>
        </form>
      </div>
      <PopupModal
        isOpen={isOpen}
        onClose={closeModal}
        popupState={popupState}
        message="NEW STUDENT HAS BEEN ADDED SUCCESSFULLY"
        onDone={handleNavigate}
        updateMessage="STUDENT HAS BEEN UPDATED SUCCESSFULLY"
        stateID={state && state}
      />
    </>
  );
}
