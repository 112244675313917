import React from "react";
import Sidebar from "../Common/component/Sidebar";
import Navbar from "../Common/component/Navbar";
import Studentviewtable from "../Common/component/Studentviewtable/Studentviewtable";

export default function StudentViewTable() {
  return (
    <section className="studentMainSection">
      <div className="container-fluid">
        <div className="row p-0">
          <div className="col-lg-2 p-0">
            <Sidebar />
          </div>
          <div className="col-lg-10  p-0">
            <div className="row">
              <div className="col-lg-12 p-0">
                <Navbar />
              </div>
              <div className="col-lg-12">
                <Studentviewtable />
                {/* <Studentcreations/> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
