import React from "react";
import Sidebar from "../Common/component/Sidebar";
import DownloadQuotation from "../Common/component/downloadquotation/DownloadQuotation";
import Navbar from "../Common/component/Navbar";
import { useLocation } from "react-router";
const Downloadquotation = () => {

  return (
    <>
      <section >
        <div className="container-fluid"
          style={{ overflow: "hidden", height: "100vh" }}
        >
          <div className="row p-0">
            <div className="col-lg-2 p-0">
              <Sidebar />
            </div>
            <div className="col-lg-10  p-0">
              <div className="col-lg-12 p-0">
                <Navbar />
              </div>
              <div className="col-lg-12">
                <DownloadQuotation />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Downloadquotation;
