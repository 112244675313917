import React, { useRef } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Images from "../../../asstes";
import { NumericFormat } from 'react-number-format';
// import convertor from "number-to-words";
const PayslipGenerator = () => {
    const payslipRef = useRef();

    const downloadPayslip = async () => {
        const element = document.getElementById("payslip");
        html2canvas(element).then((canvas) => {
            const imgData = canvas.toDataURL("image/png");
            const pdf = new jsPDF("p", "mm", "a4");
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
            pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
            pdf.save("Payslip-December-2024.pdf");
        });
    };
    const payslipData = sessionStorage.getItem("payslipdata");
    const employeeDetails = payslipData ? JSON.parse(payslipData) : null;

    console.log("employeeDetails", employeeDetails);
    const styles = {
        container: {
            margin: '20px auto',
            // width: '60%',
            fontFamily: 'Arial, sans-serif',
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr 1fr',
            border: '1pt solid #000',
            borderRadius: "4px"
        },
        cell: {
            padding: '5px 10px 5px',
            // border: '1px solid #ddd',
            textAlign: 'left',
        },
        right: {
            borderRight: "1pt solid #000"

        },
        headerCell: {
            fontWeight: '500',
            fontSize: "16px"
        },
        spanCell: {
            gridColumn: 'span 2', // Makes a cell span across two columns
        },
        padding: {
            paddingTop: "10px"
        }
    }
    // const numberName = convertor.toWords(state);
    return (
        <div style={{
            height: "100vh", overflow: "scroll", display: "flex", flexDirection: 'column', alignItems: "center", paddingBottom: "7rem", position: "relative"
        }}>
            <button onClick={downloadPayslip} className="btn-grad" style={{
                // marginTop: "20px", padding: "10px 20px", cursor: "pointer",
                position: "fixed",
                // display: flex,
                bottom: "25px",
                right: "20px",
                zIndex: 99,
                outline: "none"
                , border: "none",
                // background: "#adadad",
                borderRadius: "5px",
                // color: "#fff", backgroundImage: "linear - gradient(to right, #9D50BB 0 %, #6E48AA  51 %, #9D50BB  100 %)",
                margin: "10px",
                padding: "15px 45px",
                textAlign: "center",
                // textTransform: uppercase;
                transition: "0.5s",
                backgroundSize: "200% auto",
                color: "white",
                boxShadow: " 0 0 20px #eee",
                borderRadius: "10px",
                display: "block"
            }}>
                Download Payslip
            </button>
            {/* <div style={{ margin: "30rem" }}> */}

            <div id="payslip" ref={payslipRef} style={{ padding: "20px", width: "210mm", height: "297mm", backgroundColor: "#fff" }}>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", paddingTop: "2rem", position: 'relative' }}>
                    <div style={{
                        width: '150px', height: '150px',
                        // position: 'absolute', top: "72px",
                        // left: '116px'
                    }}>
                        <img src={Images.logoImg} alt="Log" style={{ width: "100%", height: '100%' }} />
                    </div>
                    <div style={{ zIndex: 99, width: '61%' }}>

                        <h2 style={{ textAlign: "center" }}>2K VIRTUAL WORLD</h2>
                        <h2 style={{ textAlign: "center", fontSize: "16px" }}>(Group of DVK Virtual World)</h2>
                        <p style={{ textAlign: "center" }}>#315, Bharathi Street, Yellow Board, Muttikadu, Chennai - 603112</p>
                    </div>
                </div>
                <h4 style={{ textAlign: "center", fontWeight: 400 }}>Payslip for the Month of {employeeDetails?.month},  {employeeDetails?.year}</h4>
                <div style={{ ...styles.container, }}>
                    {/* Row 1 */}

                    <div style={{ ...styles.cell, ...styles.headerCell, ...styles.padding }}>Name</div>
                    <div style={{ ...styles.cell, ...styles.right, ...styles.padding }}>: {employeeDetails.name}</div>
                    <div style={{ ...styles.cell, ...styles.headerCell, ...styles.padding }}>Employee ID</div>
                    <div style={{ ...styles.cell, ...styles.padding }}>: {employeeDetails.empId}</div>

                    {/* Row 2 */}
                    <div style={{ ...styles.cell, ...styles.headerCell }}>Designation</div>
                    <div style={{ ...styles.cell, ...styles.right }}>: {employeeDetails.designation}</div>
                    <div style={{ ...styles.cell, ...styles.headerCell }}>Bank Name</div>
                    <div style={styles.cell}>: {employeeDetails.bankName}</div>

                    {/* Row 3 */}
                    <div style={{ ...styles.cell, ...styles.headerCell }}>Department</div>
                    <div style={{ ...styles.cell, ...styles.right }}>: {employeeDetails.department}</div>
                    <div style={{ ...styles.cell, ...styles.headerCell }}>Account No.</div>
                    <div style={styles.cell}>: {employeeDetails.accountNo}</div>

                    {/* Row 4 */}
                    <div style={{ ...styles.cell, ...styles.headerCell }}>Location</div>
                    <div style={{ ...styles.cell, ...styles.right }}>: {employeeDetails.location}</div>
                    <div style={{ ...styles.cell, ...styles.headerCell }}>IFSC</div>
                    <div style={styles.cell}>: {employeeDetails.ifsc}</div>

                    {/* Row 5 */}
                    <div style={{ ...styles.cell, ...styles.headerCell }}>PAN No</div>
                    <div style={{ ...styles.cell, ...styles.right }}>: {employeeDetails.panNo}</div>

                    <div style={{ ...styles.cell, ...styles.headerCell }}>LOP</div>
                    <div style={styles.cell}>: {employeeDetails.headerLop}</div>

                    {/* Row 6 */}

                    <div style={{ ...styles.cell, ...styles.headerCell }}>Effective Work Days</div>
                    <div style={{ ...styles.cell, ...styles.right }}>: {employeeDetails.effectiveWork}</div>
                    <div style={{ ...styles.cell, ...styles.headerCell }}>Working Days</div>
                    <div style={styles.cell}>: {employeeDetails.workingDays}</div>
                </div>
                <div style={styles.container}>
                    {/* Row 1 */}
                    <div style={{ ...styles.cell, ...styles.headerCell, borderBottom: "1px solid #000" }}>Earnings </div>
                    <div style={{ ...styles.cell, ...styles.right, borderBottom: "1px solid #000" }}> Amount</div>
                    <div style={{ ...styles.cell, ...styles.headerCell, borderBottom: "1px solid #000" }}>Deductions </div>
                    <div style={{ ...styles.cell, borderBottom: "1px solid #000" }}>Amount</div>

                    {/* Row 2 */}
                    <div style={{ ...styles.cell, ...styles.headerCell }}>Basic </div>
                    <div style={{ ...styles.cell, ...styles.right }}>:   <NumericFormat
                        value={employeeDetails?.basic}
                        displayType="text"
                        thousandSeparator={true}
                        // prefix="$"
                        decimalScale={2}
                        fixedDecimalScale={true}
                    />

                    </div>
                    <div style={{ ...styles.cell, ...styles.headerCell }}>LOP </div>
                    <div style={styles.cell}>: <NumericFormat
                        value={employeeDetails?.deductionLop}
                        displayType="text"
                        thousandSeparator={true}
                        // prefix="$"
                        decimalScale={2}
                        fixedDecimalScale={true}
                    /></div>

                    {/* Row 3 */}
                    <div style={{ ...styles.cell, ...styles.headerCell }}>HRA </div>
                    <div style={{ ...styles.cell, ...styles.right }}>: <NumericFormat
                        value={employeeDetails?.hra}
                        displayType="text"
                        thousandSeparator={true}
                        // prefix="$"
                        decimalScale={2}
                        fixedDecimalScale={true}
                    /></div>
                    <div style={{ ...styles.cell, ...styles.headerCell }}>PF </div>
                    <div style={styles.cell}>: <NumericFormat
                        value={employeeDetails?.pf}
                        displayType="text"
                        thousandSeparator={true}
                        // prefix="$"
                        decimalScale={2}
                        fixedDecimalScale={true}
                    /></div>

                    {/* Row 4 */}
                    <div style={{ ...styles.cell, ...styles.headerCell }}>Conveyance Allowance</div>
                    <div style={{ ...styles.cell, ...styles.right }}>: <NumericFormat
                        value={employeeDetails?.convayanceAllowance}
                        displayType="text"
                        thousandSeparator={true}
                        // prefix="$"
                        decimalScale={2}
                        fixedDecimalScale={true}
                    /></div>
                    <div style={{ ...styles.cell, ...styles.headerCell }}>ESIC </div>
                    <div style={styles.cell}>: <NumericFormat
                        value={employeeDetails?.esic}
                        displayType="text"
                        thousandSeparator={true}
                        // prefix="$"
                        decimalScale={2}
                        fixedDecimalScale={true}
                    /></div>

                    {/* Row 5 */}
                    <div style={{ ...styles.cell, ...styles.headerCell }}>Special Allowance</div>
                    <div style={{ ...styles.cell, ...styles.right }}>: <NumericFormat
                        value={employeeDetails?.specialAllowance}
                        displayType="text"
                        thousandSeparator={true}
                        // prefix="$"
                        decimalScale={2}
                        fixedDecimalScale={true}
                    /></div>
                    <div style={{ ...styles.cell, ...styles.headerCell }}>TDS </div>
                    <div style={styles.cell}>: <NumericFormat
                        value={employeeDetails?.tds}
                        displayType="text"
                        thousandSeparator={true}
                        // prefix="$"
                        decimalScale={2}
                        fixedDecimalScale={true}
                    /></div>
                    <div style={{ ...styles.cell, ...styles.headerCell, borderTop: "1px solid #000" }}>Total Earnings (Rs)</div>
                    <div style={{ ...styles.cell, ...styles.right, borderTop: "1px solid #000" }}>: 10,000</div>

                    {/* Row 6 */}

                </div>

                <p style={{ textAlign: "left", marginBottom: '7px', margin: '0px' }}>Net Pay for the Month: Rs 10,000</p>
                <p style={{ textAlign: "left", fontSize: "12px" }}>(Rupees ${ })</p>
                <div style={{ borderTop: "1px solid #000000e6" }} />
                <p style={{ textAlign: "center", fontSize: "0.8rem", marginTop: "1rem" }}>
                    This is a system-generated payslip and does not require a signature.
                </p>
            </div >
            {/* </div> */}

        </div >
    );
};

export default PayslipGenerator;
