import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Images from "../../../asstes";
import moment from "moment";
import { useQuery } from "react-query";
import axios from "axios";
import { useNavigate } from "react-router";

export default function Downloadpayslipcom() {
  const {
    register,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const navigation = useNavigate();
  const [isparams, setIsParams] = useState()
  const [isenable, setIsEnable] = useState(false)
  const payslipget = async () => {
    return await axios.get(`https://api.2kvirtualworld.com/EMS/employee/download`, { params: isparams });
  };
  const { data, isLoading, isError, refetch } = useQuery(
    ["payslipget", isparams],
    payslipget, {
    onSuccess: (data) => {
      console.log("HHDD", data)
      const state = data;
      sessionStorage.setItem("payslipdata", JSON.stringify(data?.data))
      setIsParams({})
      setIsEnable(false)
      navigation("/PaySlipDownloadPage")
      reset()
    },
    onError: (error) => {
      console.log("ERROR", error)
      setIsParams({})
      setIsEnable(false)
      reset()
    },
    enabled: isenable
  }
  );
  const onSubmit = (data) => {
    console.log("form_data", data, moment(data?.month).format("MMMM"), moment(data?.month).format("YYYY"));
    setIsEnable(true)
    let value = {
      empId: data?.employeeid,
      month: moment(data?.month).format("MMMM"),
      dob: data?.password,//25-07-2000
      year: moment(data?.month).format("YYYY")
    }
    setIsParams(value)

  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="Downloadpayslipbg">
        <div className="downform">
          <h6 className="downtext"> DOWNLOAD PAYSLIP</h6>

          <div className="Form">
            <div className="downfirst">
              <label className="downlabel"> Employee ID </label>
              <input
                type="text"
                placeholder=""
                className="downinput"
                {...register("employeeid", { required: true })}
              ></input>
            </div>
            <div className="downfirst">
              <label className="downlabel"> Select Month</label>
              <input
                type="month"
                placeholder=""
                className="downinput"
                {...register("month")}
              ></input>
            </div>
            <div className="downfirst">
              <label className="downlabel"> Password</label>
              <div className="icon" style={{ position: "relative" }}>
                <input
                  type="password"
                  placeholder=""
                  className="downinput"
                  {...register("password", {
                    required: true,
                  })}
                />
                {/* </input>  */}
                {/* <div className="icon"> */}
                <div style={{ width: '22px', height: '22px', position: "absolute", top: "27%", right: 10 }}>

                  <img src={Images.errorIcon} alt="Erroralert" style={{ width: "100%", height: "100%" }} />
                </div>
              </div>
            </div>

            <div className="downbutton">
              <button className="downbuttext">Download</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
