import axios from "axios";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router";
import { jsPDF } from "jspdf";
import { nextCustomerId } from "../Api/GlobalApi";
import PopupModal from "../popupmodal/PopupModal";

export default function NewQuotationForm() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm();
  const [isOpen, setIsOpen] = useState(false);
  const [popupState, setPopupState] = useState({
    addData: false,
  });

  // Function to close the modal
  const closeModal = () => {
    setIsOpen(false);
    setPopupState({
      addData: false,
    });
  };

  const handleNavigate = () => {
    navigate("/viewquotation");
    setIsOpen(false);
    setPopupState({
      addData: false,
    });
  };
  console.log("state_LAAS", state);
  const [isValue, setIsValue] = useState();

  // const [startDate, setStartDate] = useState(new Date())
  const CustomerId = useQuery("nextCustomerId", nextCustomerId);
  const { mutate, isLoading, isError, isSuccess } = useMutation(
    (postData) => {
      axios.post(
        `https://api.2kvirtualworld.com/EMS/quotation/postQuotation`,
        postData
      );
    },
    {
      onSuccess: (data) => {
        console.log("User added successfully..", data);
        // setPopupOpen(1);
        setIsOpen(true);
        setPopupState({
          addData: true,
        });
      },
      onError: (error) => {
        console.error("Mutation failed:", error);
      },
    }
  );

  const update = useMutation(
    async (postData) => {
      // Return the axios request
      const response = await axios.put(
        `https://api.2kvirtualworld.com/EMS/quotation/updateQuotation/${state[0]?.customerId}`,
        postData
      );
      return response.data; // React Query expects you to return data
    },
    {
      onSuccess: (data) => {
        console.log("User updated successfully:", data);

        setIsOpen(true);
        setPopupState({
          addData: true,
        });
        // navigator("/ViewQuotation")
        // Perform additional actions on success if needed
        // Example: setPopupOpen(1);
      },
      onError: (error) => {
        console.error("Mutation failed:", error);
      },
    }
  );

  useEffect(() => {
    if (state?.length > 0) {
      state.forEach((ele) => {
        Object.entries(ele || {}).forEach(([key, value]) => {
          console.log("LLLL", key, "value", value);
          setValue(key, value || "");
        });
      });
    }
  }, [state, setValue]);

  const onSubmit = (data) => {
    console.log("DataQuotation", data);
    const flowdiagrams = isValue.replace("data:image/png;base64,", "");
    let values = {
      customerId: data?.customerId,
      customerName: data?.customerName,
      flatNo: data?.flatNo,
      area: data?.area,
      pincode: data?.pincode,
      need: data?.need,
      addDescription: data?.addDescription,
      serverCost: data?.serverCost,
      amcDuration: data?.amcDuration,
      genderTitle: data?.genderTitle,
      companyName: data?.companyName,
      phoneNumber: data?.phoneNumber,
      street: data?.phoneNumber,
      city: data?.city,
      state: data?.state,
      server: data?.server,
      flowDiagram: flowdiagrams,
    };
    console.log("DataQuotation==11", values);
    if (state && state[0]?.customerId) {
      update.mutate(values);
    } else {
      mutate(values);
    }
  };
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleFileChange = async (event, onChange) => {
    const file = event.target.files[0];
    console.log("JJJD", file);
    if (file) {
      try {
        const base64 = await convertToBase64(file);
        console.log("DDDDDD", base64);
        setIsValue(base64);
        // onChange(base64); // Save the Base64 string to the `Controller` field
      } catch (error) {
        console.error("Error converting file to Base64:", error);
      }
    }
  };
  console.log("CustomerId", CustomerId && CustomerId?.data?.data);

  return (
    <>
      <div className="formbg" style={{ overflow: "scroll" }}>
        <div className="quotationFormheader">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="quotationcreationform"
          >
            <div className="quotationcontainer">
              <div className="quotationcreationheader">
                <div>
                  <label className="quotationlabel">Customer Id</label>
                  <Controller
                    control={control}
                    name="customerId"
                    render={({ field }) => (
                      <input
                        type="text"
                        {...field}
                        value={
                          CustomerId && CustomerId?.data?.data
                            ? CustomerId && CustomerId?.data?.data
                            : ""
                        }
                        placeholder="Customer Id"
                        disabled={
                          CustomerId && CustomerId?.data?.data ? true : false
                        }
                        className="quotationinput "
                      ></input>
                    )}
                  />
                </div>
                <div>
                  <label className="quotationlabel">Customer Name</label>
                  <Controller
                    control={control}
                    name="customerName"
                    render={({ field }) => (
                      <input
                        {...field}
                        type="text"
                        placeholder="Customer Name"
                        className="quotationinput"
                      ></input>
                    )}
                  />
                </div>
                <div>
                  <label className="quotationlabel">Flat No</label>
                  <Controller
                    control={control}
                    name="flatNo"
                    render={({ field }) => (
                      <input
                        type="number"
                        placeholder="Flat No"
                        {...field}
                        className="quotationinput"
                      ></input>
                    )}
                  />
                </div>

                <div>
                  <label className="quotationlabel">Area/Village</label>
                  <Controller
                    control={control}
                    name="area"
                    render={({ field }) => (
                      <input
                        type="text"
                        {...field}
                        placeholder="Area/Village"
                        className="quotationinput"
                      ></input>
                    )}
                  />
                </div>
                <div>
                  <label className="quotationlabel">Pincode</label>
                  <Controller
                    control={control}
                    name="pincode"
                    render={({ field }) => (
                      <input
                        type="number"
                        {...field}
                        placeholder="Pincode"
                        className="quotationinput"
                      ></input>
                    )}
                  />
                  {/* <input>
          type="text"
          style={{ display: "flex", flexDirection: "column" }}
        </input> */}
                </div>
                <div>
                  <label className="quotationlabel">Need</label>
                  <Controller
                    control={control}
                    name="need"
                    render={({ field: { onChange, value } }) => (
                      <select
                        className="quotationinputselect"
                        onChange={(e) => onChange(e.target.value)}
                        value={value}
                      >
                        <option value="" disabled selected>
                          Need
                        </option>
                        <option value="option1">Option 1</option>
                        <option value="option2">Option 2</option>
                        <option value="option3">Option 3</option>
                        <option value="option4">Option 4</option>
                      </select>
                    )}
                  />

                  <div>
                    <label className="quotationlabel"></label>
                    <Controller
                      control={control}
                      name="addDescription"
                      render={({ field }) => (
                        <input
                          type="text"
                          {...field}
                          placeholder="*non mandatory"
                          className="quotationdescription"
                        ></input>
                      )}
                    />
                  </div>
                </div>
                <div>
                  <label className="quotationlabel">Cost For Need</label>
                  <Controller
                    control={control}
                    name="serverCost"
                    render={({ field }) => (
                      <input
                        type="text"
                        {...field}
                        placeholder="cost For Need"
                        className="quotationinput"
                      ></input>
                    )}
                  />
                </div>
                <div>
                  <label className="quotationlabel">AMC Duration</label>
                  <Controller
                    control={control}
                    name="amcDuration"
                    render={({ field }) => (
                      <input
                        type="text"
                        {...field}
                        placeholder="AMC Duration"
                        className="quotationinput"
                      ></input>
                    )}
                  />
                </div>
              </div>
              <div className="quotationcreationheaderright">
                <div>
                  <label
                    className="quotationlabel"
                    style={{ fontWeight: "700" }}
                  >
                    Mr / Mrs
                  </label>
                  <Controller
                    control={control}
                    name="genderTitle"
                    render={({ field: { onChange, value } }) => (
                      <select
                        className="quotationinputselect"
                        onChange={(e) => onChange(e.target.value)}
                        value={value}
                      >
                        <option value="" disabled selected>
                          Gender
                        </option>
                        <option value="Mr">Mr</option>
                        <option value="Ms">Ms</option>
                        <option value="Mrs">Mrs</option>
                        {/* <option value="option4">Option 4</option> */}
                      </select>
                    )}
                  />
                </div>
                <div>
                  <label
                    className="quotationlabel"
                    style={{ fontWeight: "700" }}
                  >
                    Company Name
                  </label>
                  <Controller
                    control={control}
                    name="companyName"
                    render={({ field }) => (
                      <input
                        type="text"
                        {...field}
                        placeholder="Company Name"
                        className="quotationinput"
                      ></input>
                    )}
                  />
                </div>

                <div>
                  <label
                    className="quotationlabel"
                    style={{ fontWeight: "700" }}
                  >
                    Phone No
                  </label>
                  <Controller
                    control={control}
                    name="phoneNumber"
                    render={({ field }) => (
                      <input
                        type="number"
                        {...field}
                        placeholder="phoneNumber"
                        className="quotationinput"
                      ></input>
                    )}
                  />
                </div>
                <div>
                  <label
                    className="quotationlabel"
                    style={{ fontWeight: "700" }}
                  >
                    Street
                  </label>
                  <Controller
                    control={control}
                    name="street"
                    render={({ field }) => (
                      <input
                        type="text"
                        {...field}
                        placeholder="Street"
                        className="quotationinput"
                      ></input>
                    )}
                  />
                </div>

                <div className="quoationlabel">
                  <label
                    className="quotationlabel"
                    style={{ fontWeight: "700" }}
                  >
                    City
                  </label>
                  <Controller
                    control={control}
                    name="city"
                    render={({ field }) => (
                      <input
                        type="text"
                        {...field}
                        placeholder="City"
                        className="quotationinput"
                      ></input>
                    )}
                  />
                </div>
                <div>
                  <label
                    className="quotationlabel"
                    style={{ fontWeight: "700" }}
                  >
                    State
                  </label>
                  <Controller
                    control={control}
                    name="state"
                    render={({ field }) => (
                      <input
                        type="text"
                        {...field}
                        placeholder="State"
                        className="quotationinput"
                      ></input>
                    )}
                  />
                </div>
                <div>
                  <label className="quotationlabel">Server</label>
                  <Controller
                    control={control}
                    name="server"
                    render={({ field }) => (
                      <input
                        type="text"
                        {...field}
                        placeholder="Server"
                        className="quotationinput"
                      ></input>
                    )}
                  />
                </div>
                <div>
                  <label className="quotationlabel">Const For Server</label>
                  <Controller
                    control={control}
                    name="serverCost"
                    render={({ field }) => (
                      <input
                        type="text"
                        placeholder="Const For Server"
                        {...field}
                        className="quotationinput"
                      ></input>
                    )}
                  />
                </div>
                <div>
                  <label className="quotationlabel">Flow Diagram Upload</label>
                  <Controller
                    control={control}
                    name="flowDiagrams"
                    render={({ field: { onChange, ...field } }) => (
                      <input
                        type="file"
                        {...field}
                        placeholder=" "
                        onChange={(event) => handleFileChange(event, onChange)}
                        className="quotationinputfile"
                      ></input>
                    )}
                  />
                </div>

                <div class="btn-group dropend" style={{ width: "400px" }}>
                  <button
                    type="submit"
                    class="btn btn-primary"
                    style={{
                      gap: "50px",
                      position: "absolute",
                      left: "250px",
                      width: "150px",
                      height: "55px",
                      backgroundColor: "#7A1CAC",
                    }}
                  >
                    {isLoading ? "Loading..." : "Get Quotation"}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <PopupModal
        isOpen={isOpen}
        onClose={closeModal}
        popupState={popupState}
        message="NEW QUOTATION HAS BEEN ADDED SUCCESSFULLY"
        onDone={handleNavigate}
        updateMessage="QUOTATION HAS BEEN UPDATED SUCCESSFULLY"
        stateID={state && state[0]?.customerId}
      />
    </>
  );
}
