import React from "react";
import Sidebar from "../Common/component/Sidebar";
import Navbar from "../Common/component/Navbar";
import StudentCreationForm from "../Common/component/StudentCreationForm/StudentCreationForm";
// import StudentCreationForm from "../Common/component/StudentCreationForm/StudentCreationForm";
//import StudentCreationForm from "../Common/component/StudentcreationForm/StudentcreationForm";

export default function StudentFormPg() {
  return (
   
      <section className="studentMainSection">
        <div className="container-fluid">
          <div className="row p-0">
            <div className="col-lg-2 p-0">
           <Sidebar/>
            </div>
            <div className="col-lg-10  p-0">
              <div className="row ">
                <div className="col-lg-12 p-0">
                  <Navbar/>        
                </div>
                <div className="col-lg-12">
                  
                    <StudentCreationForm/>
                    {/* <Studentcreations/> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
   
  );
} 



