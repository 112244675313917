import React from "react";
import notification from "./png/notification.png";
import logout from "./png/logout.png";
import logo from "./png/logo.png";
import email from "./png/email.png";
import pen from "./png/pen.png";
import download from "./png/download.png";
import erroralert from "./png/erroralert.png";
import search from "./png/search.png";
import vector from "./png/vector.png";
import rulercrosspen from "./png/rulercrosspen.png";
import password from "./png/password.png";
import close from "./png/close.png";
const Images = {
  logoImg: logo,
  notificationIcon: notification,
  logoutIcon: logout,
  emailIcon: email,
  penImg: pen,
  searchIcon: search,
  downloadIcon: download,
  vectorIcon: vector,
  rulerIcon: rulercrosspen,
  errorIcon: erroralert,
  passwordIcon: password,
  close: close,
};
export default Images;
