import React from "react";
import LoginForm from "../Common/component/LoginForm";

export default function login() {
  return (
    <section className="loginMainSection">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 d-flex justify-content-center align-items-center">
            <LoginForm />
          </div>
        </div>
      </div>
    </section>
  );
}
