import React, { useRef, useState } from 'react';
import './Invoice.css'; // Create a separate CSS file for styling
import { useReactToPrint } from "react-to-print";
import html2pdf from "html2pdf.js";

const SalesInvoiceView = () => {
  const componentRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
 

  const handleDownloadPdf = async () => {
    setIsLoading(true); // Set loading state to true

    const element = componentRef.current;

    try {
      // Generate PDF
      await html2pdf().from(element).save("invoice.pdf");
    } catch (error) {
      console.error("PDF download failed: ", error);
    }

    setIsLoading(false); 
  };
  const invoiceData = {
    invoiceNumber: '2K2024143',
    date: '29/08/2024',
    accountDetails: {
      name: 'DVK VIRTUAL WORLD',
      bank: 'HDFC BANK',
      branch: 'Kelambakkam',
      accNumber: '50200092481949',
      ifsc: 'Hdfc0002075',
    },
    billTo: {
      name: 'BORA DIAGNOSTIC CENTRE',
      address: 'No.99/39, Subramani Mudali Street, SAIDAPET, CHENNAI-600015.',
      state: 'Tamil Nadu',
    },
    startDate: '26/08/2024',
    orderedBy: 'Mr. Surendar Kumar Bora',
    paymentDetails: {
      description: 'Payment of billing Software web application development (With Server)',
      cgst: '9%',
      sgst: '9%',
      total: '',
    },
    footer: 'D.V.Kannan',
  };

  return (
    <div className='invoice_view'>
    <div className="invoice-container" ref={componentRef}>
      <div className="invoice-header">
        <div className="company-details">
          <h2>2KVIRTUALWORLD</h2>
          <p>(Group of DVK Virtual World)</p>
          <p>#315 Bharathi Street, Muttukadu, Chennai-603112</p>
          <p>Mobile No: 8667099176, 9092540241</p>
          <p>info@2kvirtualworld.com</p>
          <p>GSTIN: 33CNWPV3228GIZI</p>
        </div>
        <div className="invoice-title">
          <h3>Proforma Invoice</h3>
          <p>Invoice Number: {invoiceData.invoiceNumber}</p>
          <p>Date: {invoiceData.date}</p>
        </div>
      </div>

      <div className="invoice-body">
        <div className="account-details">
          <h4>Account Details</h4>
          <p>{invoiceData.accountDetails.name}</p>
          <p>{invoiceData.accountDetails.bank}</p>
          <p>{invoiceData.accountDetails.branch}</p>
          <p>Acc Number: {invoiceData.accountDetails.accNumber}</p>
          <p>IFSC: {invoiceData.accountDetails.ifsc}</p>
        </div>
        <div className="bill-to">
          <h4>BILL TO</h4>
          <p>{invoiceData.billTo.name}</p>
          <p>{invoiceData.billTo.address}</p>
          <p>{invoiceData.billTo.state}</p>
        </div>

        <div className="order-details">
          <div className="start-date">
            <p>START DATE: {invoiceData.startDate}</p>
          </div>
          <div className="ordered-by">
            <p>ORDERED BY: {invoiceData.orderedBy}</p>
          </div>
        </div>

        <table className="invoice-table">
          <thead>
            <tr>
              <th>S.NO</th>
              <th>Description</th>
              <th>Amount (₹)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>{invoiceData.paymentDetails.description}</td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>CGST {invoiceData.paymentDetails.cgst}</td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>SGST {invoiceData.paymentDetails.sgst}</td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="invoice-footer">
        <p>Date: {invoiceData.date}</p>
        <p>Total: ₹_________</p>
        <p>Authorized Signatory: {invoiceData.footer}</p>
      </div>
    </div>
    <div style={{width:"100%",display:"flex",justifyContent:"center",  marginTop: '3rem'}}>

<div className='btn-invoice' onClick={handleDownloadPdf}>
<p >{isLoading?"Loading":"Download"}</p>
</div>
    </div>
    </div>
  );
};

export default SalesInvoiceView;
