import axios from "axios";
import React from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";

export default function Uploadpayslipcom() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm();
  const { mutate, isLoading, isError, isSuccess } = useMutation(
    (postData) => {
      axios.post(`https://api.2kvirtualworld.com/EMS/employee/upload`, postData)

    },
    {
      onSuccess: (data) => {
        console.log("User added successfully..", data);
        // setPopupOpen(1);
        reset()
      },
      onError: (error) => {
        console.error("Mutation failed:", error);
        reset()
      },
    }
  );
  const onSubmit = (data) => {
    console.log("form_data", data);
    const formData = new FormData();
    formData.append("file", data?.file[0]);

    mutate(formData)
  };

  return (
    <div className="Uploadpayslipbg">
      <div className="Uploadform">
        <h6 className="uploadtext">Upload File</h6>

        <input
          type="file"
          placeholder=""
          className="uploadinput"
          accept=".csv"
          {...register("file", { required: true })}
        />

        {errors.file && <p style={{ color: "red" }}>Please upload a file</p>}

        <div>
          <button
            className="uploadbutton"
            type="submit"
            onClick={handleSubmit(onSubmit)}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}
