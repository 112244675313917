import React from "react";
import Sidebar from "../Common/component/Sidebar";
import Navbar from "../Common/component/Navbar";

import ViewCustomerTable from "../Common/component/ViewCustomerTable/ViewCustomerTable";
import CustomerView from "../Common/component/ViewCustomerTable/CustomerView";

export default function ViewCustomer() {
  return (
    <>
      <section className="viewCustomerMainSection">
        <div className="container-fluid">
          <div className="row p-0">
            <div className="col-lg-2 p-0">
              <Sidebar />
            </div>
            <div className="col-lg-10  p-0">
              <div className="row">
                <div className="col-lg-12 p-0">
                  <Navbar />
                </div>
                <div className="col-lg-12">
                  <ViewCustomerTable />
                  {/* <CustomerView/> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
