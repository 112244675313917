import React from "react";
import Sidebar from "../Common/component/Sidebar";
import Navbar from "../Common/component/Navbar";
import AddSales from "../Common/component/AddSaleForm/AddSaleForm";
// import AddSalesForm from "../Common/component/AddSales/AddSales";
// import AddSaleForm from "../Common/component/AddSaleForm/AddSaleForm";
// import AddSales from "../Common/component/AddSales/AddSales";

export default function AddSalesScreen() {
  return (
    <section>
      <div
        className="container-fluid"
        style={{ overflow: "hidden", height: "100vh" }}
      >
        <div className="row p-0">
          <div className="col-lg-2 p-0">
            <Sidebar />
          </div>
          <div className="col-lg-10  p-0">
            <div className="row ">
              <div className="col-lg-12 p-0">
                <Navbar />
              </div>
              <div className="col-lg-12 ">
                <AddSales />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
