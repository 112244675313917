// import axios from 'axios';
import React, { useEffect, useState } from "react";
// import { useQuery } from 'react-query';
import { useNavigate } from "react-router-dom";
// import * as XLSX from "xlsx";
// import Images from '../../asset';
import { useTable } from "react-table";
import Images from "../../../asstes";
import axios from "axios";
import { useQuery } from "react-query";
import PopupModal from "../popupmodal/PopupModal";
// import ReactPaginate from "react-paginate";
export default function Viewsalescom() {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [popupState, setPopupState] = useState({
    removeConfirm: false,
    deleteId: null,
    removeSucess: false,
  });

  // Function to close the modal
  const closeModal = () => {
    setIsOpen(false);
    setPopupState({
      removeConfirm: false,
      removeSucess: false,
      deleteId: null,
    });
  };
  const openDeleteModal = (registerNumber) => {
    setIsOpen(true);
    setPopupState({
      removeConfirm: true,
      deleteId: registerNumber, // Pass the register number for deletion
    });
  };

  
  const fetchDataManagement = async () => {
    return await axios.get(
      `https://api.2kvirtualworld.com/EMS/sales/getSales`
    );
  };

  const { data, isLoading, isError, refetch, isFetched } = useQuery(
    "data",
    fetchDataManagement
  );

  const deletetogglePopup = async (state) => {
    try {
      await axios.delete(
        `https://api.2kvirtualworld.com/EMS/employee/Delete/${state}`
      );
    } catch (error) {
      console.log("Error", error);
    }
    refetch();
    setPopupState({
      removeConfirm: false,
      removeSucess: true,
    });
    // setPopupOpen(false);
  };

  const updateFunction = (employeeId)=>{
    try {
    console.log("Fetched Data:", data?.data);
    console.log("Customer ID to match:", employeeId);
    
    const values = data?.data?.filter(
      (ele) => String(ele?.employeeId) === String(employeeId)
    );

    if (values?.length > 0) {
      console.log("Matching customer found:", values);
      navigate("/", { state: values });
    } else {
      console.warn("No matching customer found.");
    }
  } catch (error) {
    console.error("Error during navigation:", error);
  }
}

  const columns = React.useMemo(
    () => [
      // { Header: "S.No", accessor: "sno" },
      { Header: "Invoice Type", accessor: "invoiceType" },
      { Header: "Customer Id", accessor: "customerId" },
      { Header: "Customer Name", accessor: "customerName" },
      { Header: "Company Name", accessor: "companyName" },
      { Header: "Address", accessor: "address" },
      // { Header: "City", accessor: "city" },
      // { Header: "Pincode", accessor: "pincode" },
      { Header: "Start Date", accessor: "startDate" },
      { Header: "Description", accessor: "description" },
      { Header: "Payment Mode", accessor: "paymentMode" },
      { Header: "Payment Date", accessor: "paymentDate" },
      { Header: "projectAmount", accessor: "projectAmount" },
      { Header: "CGST", accessor: "cgst" },
      { Header: "SGST", accessor: "sgst" },
      // { Header: "Product Copy", accessor: "productCopy" },
      {
        Header: "Product Copy",
        accessor: "productCopy",
        Cell: ({ row }) => (
          // console.log("FFFFF", )

          <div>
            <button
              // onClick={() => deletetogglePopup(row.original.employeeId)}
              onClick={()=>openDeleteModal(row.original.employeeId)}
              style={{
                marginRight: "10px",
                cursor: "pointer",
                border: "none",
                background: "transparent",
              }}
            >
              🗑️
            </button>
            <button
              onClick={() =>
                // setDeleteIndex()
              updateFunction(row.original.employeeId)
                // navigate("/", { state: values })
               }
              //  setSelectedCustomer(row.original.customerId)}
              style={{
                cursor: "pointer",
                border: "none",
                background: "transparent",
              }}
            >
              ✏️
            </button>
          </div>
        ),
      }, 
      
    ],
    []
  );
    

  const tabelcolums = data?.data.map((item) => ({
    ...item,
    address: `${item.flatNo ? item.flatNo : ""}, ${
      item.street ? item.street : ""
    }, ${item.area ? item.area : ""}, ${item.city ? item.city : ""}, ${
      item.state ? item.state : ""
    } - ${item.pincode ? item.pincode : 0}`,
    select: <input type="checkbox" />,
  }));

  

  const filteredData =
    tabelcolums && tabelcolums
      ? tabelcolums &&
        tabelcolums?.filter((item) => {
          console.log("item", item);
          return (
            item?.customerId
              ?.toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            item?.customerName?.toLowerCase().includes(searchTerm.toLowerCase())
          );
        })
      : [];
  const tableInstance = useTable({ columns, data: filteredData });
  console.log("filteredData", filteredData);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;
  // console.log("tabelcolums", tabelcolums, "columns", columns, "tableInstance", tableInstance);
  console.log("rows", rows);
  return (
    <>
      <div className="viewtable">
        <div
          className="searchbaremptab"
          style={{
            display: "flex",
            alignItems: "center",
            background: "#fff",
            width: "20%",
            margin: "15px 25px",
            height: "35px",
            borderRadius: "5px",
            justifyContent: "space-between",
            padding: "0px 10px",
            border: "2px solid #7a1cac",
          }}
        >
          <input
            type="text"
            className=" search"
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{
              width:'100%',
              border: "none",
              outline: "none",
              borderRadius: "0px",
              height: "20PX",
            }}
          ></input>
          <div style={{ width: "22px", height: "22px" }}>
            <img
              src={Images.searchIcon}
              alt="searchicon"
              style={{ width: "100%", height: "100%" }}
            />
          </div>
        </div>

        <div className="emptable">
          <table className="empdetailstable" cellPadding="10px">
            <thead>
              {headerGroups?.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      className="viewcustomerheader"
                      {...column.getHeaderProps()}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            {/* <tbody>
              {rows?.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()} className="viewcustomerdata">
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody> */}
            <tbody {...getTableBodyProps()}>
              {rows.length > 0 ? (
                rows.map((row) => {
                  console.log("row", row);
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td
                          className="viewcustomerdata"
                          {...cell.getCellProps()}
                        >
                          {cell.render("Cell")}
                        </td>
                      ))}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td
                    colSpan={columns.length}
                    style={{ textAlign: "center", padding: "10px" }}
                  >
                    No records found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <PopupModal
        isOpen={isOpen}
        onClose={closeModal}
        popupState={popupState}
        setPopupState={setPopupState}
        deleteHandler={deletetogglePopup}
        isLoading={isLoading}
        message="SALES HAS BEEN DELETED SUCCESSFULLY"
      />
    </>
  );
}
