import React from "react";
import Sidebar from "../Common/component/Sidebar";
import Navbar from "../Common/component/Navbar";
import SalesInvoiceView from "../Common/component/SalesInvoiceView/SalesInvoiceView";

export default function SalesInvoice                                                                                                                                                                                                  () {
  return (
    <>
      <section>
        <div className="container-fluid" style={{height:'100vh',overflow:"hidden"}}>
          <div className="row p-0">
            <div className="col-lg-2 p-0">
          
              <Sidebar/>
            </div>
            <div className="col-lg-10  p-0">
              <div className="row ">
                <div className="col-lg-12 p-0">
                  <Navbar/>

                  
                </div>
                <div className="col-lg-12 " >
                  
                    <SalesInvoiceView/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
