import React, { useState } from "react";

const CoursePaymentScreen = () => {
    const [courseDetails, setCourseDetails] = useState({
        title: "React.js Mastery Course",
        description: "Learn React.js from basics to advanced.",
        duration: "3 Months",
        price: 200,
        discount: 20,
    });

    const [paymentPlan, setPaymentPlan] = useState("full"); // 'full' or 'installments'
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
    const [paymentStatus, setPaymentStatus] = useState({
        firstInstallmentPaid: false,
        secondInstallmentPaid: false,
    });

    const totalAmount = courseDetails.price - courseDetails.discount;

    const handlePayment = (installment) => {
        if (!selectedPaymentMethod) {
            alert("Please select a payment method.");
            return;
        }

        if (installment === "first") {
            setPaymentStatus((prev) => ({ ...prev, firstInstallmentPaid: true }));
            alert("First installment paid successfully!");
        } else if (installment === "second") {
            if (!paymentStatus.firstInstallmentPaid) {
                alert("You need to pay the first installment first.");
                return;
            }
            setPaymentStatus((prev) => ({ ...prev, secondInstallmentPaid: true }));
            alert("Second installment paid successfully! Payment complete.");
        }
    };

    const renderInstallmentDetails = () => {
        const halfAmount = (totalAmount / 2).toFixed(2);

        return (
            <div>
                <h3>Installment Plan</h3>
                <p>
                    <strong>First Installment:</strong> ${halfAmount}{" "}
                    {paymentStatus.firstInstallmentPaid ? "(Paid)" : "(Pending)"}
                </p>
                <p>
                    <strong>Second Installment:</strong> ${halfAmount}{" "}
                    {paymentStatus.secondInstallmentPaid ? "(Paid)" : "(Pending)"}
                </p>
                <button
                    onClick={() => handlePayment("first")}
                    disabled={paymentStatus.firstInstallmentPaid}
                    style={{
                        width: "100%",
                        padding: "10px",
                        backgroundColor: paymentStatus.firstInstallmentPaid ? "#ccc" : "#4CAF50",
                        color: "white",
                        border: "none",
                        cursor: paymentStatus.firstInstallmentPaid ? "not-allowed" : "pointer",
                        marginTop: "10px",
                    }}
                >
                    Pay First Installment
                </button>
                <button
                    onClick={() => handlePayment("second")}
                    disabled={!paymentStatus.firstInstallmentPaid || paymentStatus.secondInstallmentPaid}
                    style={{
                        width: "100%",
                        padding: "10px",
                        backgroundColor: paymentStatus.secondInstallmentPaid ? "#ccc" : "#4CAF50",
                        color: "white",
                        border: "none",
                        cursor: paymentStatus.secondInstallmentPaid ? "not-allowed" : "pointer",
                        marginTop: "10px",
                    }}
                >
                    Pay Second Installment
                </button>
            </div>
        );
    };

    return (
        <div style={{ maxWidth: "600px", margin: "auto", padding: "20px" }}>
            <h1>Course Payment</h1>

            {/* Course Details */}
            <div style={{ border: "1px solid #ccc", padding: "15px", marginBottom: "15px" }}>
                <h2>{courseDetails.title}</h2>
                <p>{courseDetails.description}</p>
                <p>
                    <strong>Duration:</strong> {courseDetails.duration}
                </p>
            </div>

            {/* Payment Summary */}
            <div style={{ border: "1px solid #ccc", padding: "15px", marginBottom: "15px" }}>
                <h3>Payment Summary</h3>
                <p>
                    <strong>Price:</strong> ${courseDetails.price}
                </p>
                <p>
                    <strong>Discount:</strong> -${courseDetails.discount}
                </p>
                <h4>Total: ${totalAmount}</h4>
            </div>

            {/* Payment Plan Selection */}
            <div style={{ border: "1px solid #ccc", padding: "15px", marginBottom: "15px" }}>
                <h3>Select Payment Plan</h3>
                <label>
                    <input
                        type="radio"
                        name="paymentPlan"
                        value="full"
                        onChange={(e) => setPaymentPlan(e.target.value)}
                        checked={paymentPlan === "full"}
                    />{" "}
                    Full Payment
                </label>
                <br />
                <label>
                    <input
                        type="radio"
                        name="paymentPlan"
                        value="installments"
                        onChange={(e) => setPaymentPlan(e.target.value)}
                        checked={paymentPlan === "installments"}
                    />{" "}
                    Pay in 2 Installments
                </label>
            </div>

            {/* Installment Details */}
            {paymentPlan === "installments" && renderInstallmentDetails()}

            {/* Full Payment */}
            {paymentPlan === "installments" && renderInstallmentDetails()}
        </div>
    );
};

export default CoursePaymentScreen;
