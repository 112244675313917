
import { EMPLOYEE_DETAILS, LOGIN_SUCCESS, LOGOUT, SAVE_VIEW_EMPLOYEE } from "./ActionTypes";

export const loginSuccess = () => {
    return {
        type: LOGIN_SUCCESS,
    };
};
export const logout = () => {
    return {
        type: LOGOUT,
    };
};

export const saveViewEmployee = (employeeData) => {
    return {
        type: SAVE_VIEW_EMPLOYEE,
        payload: employeeData,
    };
};
export const employeeDetails = (employeeDetails) => {
    return {
        type: EMPLOYEE_DETAILS,
        payload: employeeDetails,
    };
};
