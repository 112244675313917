import React from 'react'
import Sidebar from '../Common/component/Sidebar'
import Navbar from '../Common/component/Navbar'

import ViewQuotationTable from '../Common/component/Viewquotationtable/ViewQuotationTable'
import PayslipGenerator from '../Common/component/PayslipDownload/PayslipDownload'
import { useLocation } from 'react-router'
// import Viewsalescom from '../Common/component/Viewsalescom/Viewsalescom'


export default function PaySlipDownloadPage() {
  // const { state } = useLocation()
  // console.log("State", state)

  return (
    <section>
      <div className="container-fluid" style={{ height: '100vh', overflow: "hidden" }}>
        <div className="row p-0">
          <div className="col-lg-2 p-0">

            <Sidebar />
          </div>
          <div className="col-lg-10  p-0">
            <div className="row ">
              <div className="col-lg-12 p-0">
                <Navbar />


              </div>
              <div className="col-lg-12 ">

                <PayslipGenerator />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
