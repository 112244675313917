import React, { useState } from "react";
import Images from "../../asstes";
import { useNavigate } from "react-router";
import { useForm, Controller } from "react-hook-form";
import InputComponent from "./InputComponent/InputComponent";
import { useMutation } from "react-query";
import axios from "axios";
import { useDispatch } from "react-redux";
import { loginSuccess, employeeDetails } from "../Redux/Actions";

export default function LoginForm() {
  const dispatch = useDispatch();
  const [ischeck, setIsCheck] = useState(false);
  const navigation = useNavigate();
  const {
    register,
    handleSubmit,
    handleReset,
    formState: { errors },
    reset,
    control,
  } = useForm();
  const { mutate, isLoading, isError } = useMutation((postData) => axios.post("https://api.2kvirtualworld.com/EMS/employee/login", postData), {
    onSuccess: (data) => {
      dispatch(employeeDetails(data && data?.data?.employee))
      dispatch(loginSuccess())
    }
  })
  const onSubmit = (data) => {
    console.log("FORM_DATA", data);
    mutate(data)
  };
  return (
    <div>
      <div className="overal-container">
        <div className="container">
          <div className="logo-container">
            <img src={Images.logoImg} alt="logo" />
          </div>
        </div>
        <h1 style={{ color: "#7a1cac" }}>
          EMPLOYEE MANAGEMENT
          <br />
          SYSTEM
        </h1>
      </div>

      <div className="form-container">
        <form className="formcontainer" onSubmit={handleSubmit(onSubmit)}>
          <div className="email-container">
            <input
              type="email"
              placeholder="Email"
              className="mail"
              {...register("loginEmail", {
                required: true,
                validate: {
                  matchPattern: (v) =>
                    /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                    "Email address must be a valid address",
                },
              })}
            />
            <img src={Images.emailIcon} className="email-icon" />
          </div>
          {errors.email && (
            <p style={{ margin: 0, padding: 0 }}>Email Not Valid</p>
          )}

          {/* <img src={Images.emailIcon} className="email-icon"/>
          </div> */}
          {/* </div> */}
          {/* </div> */}
          <div >
            <div className="pass-container">
              <input
                type={ischeck ? "text" : "password"}
                placeholder="Password"
                className="pass"
                {...register("loginPassword")}
              />
              <img src={Images.passwordIcon} className="pass-icon" /></div>
            {errors.password && (
              <p style={{ margin: 0, padding: 0 }}>Invalid Password</p>
            )}
          </div>

          <div className="tick">
            <div className="check">
              <input
                type="checkbox"
                id="remember"
                className="check-box"
                value={ischeck}
                onClick={() => setIsCheck(!ischeck)}
              />
              <label for="remember">Remember Me</label>
            </div>
            <button type="submit" className="login-button">
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
