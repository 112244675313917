// import axios from 'axios';
import React, { useEffect, useState } from "react";
// import { useQuery } from 'react-query';
import { useLocation, useNavigate } from "react-router-dom";
// import * as XLSX from "xlsx";
// import Images from '../../asset';
import { useTable } from "react-table";
import Images from "../../../asstes";
import { getViewStudent } from "../Api/GlobalApi";
import { useQuery } from "react-query";
import axios from "axios";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import PopupModal from "../popupmodal/PopupModal";
// import ReactPaginate from "react-paginate";
export default function Studentviewtable() {
  const [searchTerm, setSearchTerm] = useState("");
  // const [filteredData, setFilteredData] = useState([]);
  const navigate = useNavigate();
  const { state } = useLocation();

  
  const [isOpen, setIsOpen] = useState(false);
  const [popupState, setPopupState] = useState({
    removeConfirm: false,
    deleteId: null,
    removeSucess: false,
  });

  // Function to close the modal
  const closeModal = () => {
    setIsOpen(false);
    setPopupState({
      removeConfirm: false,
      removeSucess: false,
      deleteId: null,
    });
  };
  const openDeleteModal = (registerNumber) => {
    setIsOpen(true);
    setPopupState({
      removeConfirm: true,
      deleteId: registerNumber, // Pass the register number for deletion
    });
  };
  const { data, isLoading, isError, refetch } = useQuery(
    "getViewStudent",
    getViewStudent
  );
  // let details = data && data?.data;
  // console.log("details----->",details);
  const deletetogglePopup = async (state) => {
    console.log("DDSA:", state);

    try {
      await axios.delete(
        `https://api.2kvirtualworld.com/EMS/students/deleteStudent/${state}`
      );
    } catch (error) {
      console.log("Error", error);
    }
    refetch();
    setPopupState({
      removeConfirm: false,
      removeSucess: true,
    });
    // setPopupOpen(false);
  };

  const [selectedCustomer, setSelectedCustomer] = useState(data);

  useEffect(() => {
    try {
      // console.log("Fetched Data:", data?.data);
      // console.log("Customer ID to match:", selectedCustomer);

      // Filter the data based on the customerId
      const values =
        data &&
        data?.data?.filter(
          (ele) => String(ele?.registerNumber) === String(selectedCustomer)
        );
      if (values?.length > 0) {
        // console.log("Matching customer found:", values);
        // Navigate to NewQuotation page with the matching data
        navigate("/StudentFormPg", { state: values });
      } else {
        console.warn("No matching customer found.");
      }
    } catch (error) {
      console.error("Error during navigation:", error);
    }
  }, [data, selectedCustomer]);

  const columns = React.useMemo(
    () => [
      // { Header: "S.No", accessor: "sno" },
      // { Header: "Select", accessor: "select" },
      { Header: "Register No", accessor: "registerNumber" },
      { Header: "Student Name", accessor: "studentName" },
      { Header: "College Name", accessor: "collegeName" },
      { Header: "Course", accessor: "course" },
      { Header: "Mobile Number", accessor: "mobileNumber" },
      { Header: "Alter Number", accessor: "emergencyNumber" },
      { Header: "Aadhar Number", accessor: "aadharNumber" },
      { Header: "DOB", accessor: "dob" },
      { Header: "Address", accessor: "address" },
      { Header: "City", accessor: "city" },
      { Header: "Pincode", accessor: "pincode" },
      { Header: "Date Of Joining", accessor: "joiningDate" },
      { Header: "Date Of Reveling", accessor: "relievingDate" },
      { Header: "Status", accessor: "status" },
      {
        Header: "Product Copy",
        accessor: "productCopy",
        Cell: ({ row }) => (
          <div>
            <button
              // onClick={() => deletetogglePopup(row.original.registerNumber)}
              onClick={() => openDeleteModal(row.original.registerNumber)}
              style={{
                marginRight: "10px",
                cursor: "pointer",
                border: "none",
                background: "transparent",
              }}
            >
              🗑️
            </button>
            <button
              onClick={() => setSelectedCustomer(row.original.registerNumber)}
              style={{
                cursor: "pointer",
                border: "none",
                background: "transparent",
              }}
            >
              ✏️
            </button>
          </div>
        ),
      },
    ],
    []
  );

  const datas = [
    {
      sno: 1,
      studentName: "DEEPA",
      registerNo: "2KR001",
      collegeName: "AIHTr",
      course: "JAVA",
      age: 25,
      mobileNumber: "1234567890",
      alterNumber: "9941466760",
      address: "abc street chennai",
      city: " chennai",
      pincode: "603110",
      description: "developer",
      dateOfJoining: "12.09.2024",
      dateOfReveling: "null",
      status: "working",

      // editId: <img src={Images.pencilIcon}/>
    },
    {
      sno: 2,
      studentName: "DEEPIKA",
      registerNo: "2KR002",
      collegeName: "AIHTr",
      course: "JAVA",
      age: 25,
      mobileNumber: "1234567890",
      alterNumber: "9941466760",
      address: "abc street chennai",
      city: " chennai",
      pincode: "603110",
      description: "developer",
      dateOfJoining: "12.09.2024",
      dateOfReveling: "null",
      status: "working",

      // editId: <img src={Images.pencilIcon}/>
    },
    {
      sno: 3,
      studentName: "DEEPAK",
      registerNo: "2KR003",
      collegeName: "AIHTr",
      course: "JAVA",
      age: 25,
      mobileNumber: "1234567890",
      alterNumber: "9941466760",
      address: "abc street chennai",
      city: " chennai",
      pincode: "603110",
      description: "developer",
      dateOfJoining: "12.09.2024",
      dateOfReveling: "null",
      status: "working",

      // editId: <img src={Images.pencilIcon}/>
    },
  ];
  const tabelcolums =
    data &&
    data?.data?.map((item) => ({
      ...item,
      address: `${item?.flatNo}, ${item?.street}, ${item?.area}, ${item?.city}, ${item?.state} - ${item?.pincode}`,
      select: <input type="checkbox" />,
    }));

 

  const filteredData =
    tabelcolums && tabelcolums
      ? tabelcolums &&
        tabelcolums?.filter(
          (item) =>
            item?.studentName
              ?.toLowerCase()
              .includes(searchTerm?.toLowerCase()) ||
            item?.registerNumber
              ?.toLowerCase()
              .includes(searchTerm?.toLowerCase())
        )
      : [];

  const tableInstance = useTable({ columns, data: filteredData });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  // console.log("table", tabelcolums);

  const downloadExcel = () => {
    // Prepare empty data if there's no valid data to export
    const exportData =
      filteredData && filteredData?.length > 0 ? filteredData : data;

    const tableDatas =
      exportData && exportData?.length > 0
        ? exportData?.map((data, index) => ({
            "S.NO": index + 1,
            "REGISTER NUMBER": data?.registerNumber || null,
            "STUDENT NAME": data?.studentName || null,
            "COLLEGE NAME": data?.collegeName || null,
            COURSE: data?.course || null,
            "MOBILE NUMBER": data?.mobileNumber || null,
            "ALTENATE NUMBER": data?.emergencyNumber || null,
            "AADHAR NUMBER": data?.aadharNumber || null,
            "DATE OF BIRTH": data?.dob || null,
            CITY: data?.city || null,
            PINCODE: data?.pincode || null,
            "DATE OF JOINING": data?.joiningDate || null,
            "DATE OF RELIEVING": data?.dateOfReveling || null,
          }))
        : []; // Default to an empty array

    // Create a new workbook and add an empty sheet if there's no data
    const workbook = XLSX.utils.book_new();
    const tableWorksheet = XLSX.utils.json_to_sheet(
      tableDatas?.length > 0 ? tableDatas : [{}],
      {
        skipHeader: false,
      }
    );

    // Optionally set headers even if no data
    if (tableDatas?.length === 0) {
      XLSX.utils.sheet_add_aoa(tableWorksheet, [
        [
          "S.NO",
          "REGISTER NUMBER",
          "STUDENT NAME",
          "COLLEGE NAME",
          "COURSE",
          "MOBILE NUMBER",
          "ALTENATE NUMBER",
          "AADHAR NUMBER",
          "DATE OF BIRTH",
          "CITY",
          "PINCODE",
          "DATE OF JOINING",
          "DATE OF RELIEVING",
        ],
      ]);
    }

    XLSX.utils.book_append_sheet(workbook, tableWorksheet, "Table Data");

    // Generate the Excel file
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    // Save the Excel file
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, "View Student.xlsx");
  };
  return (
    <>
      <div className="col-lg-12  view-customer-heading-block pt-3 px-3">
        <div className="customerSearchBlock">
          <input
            type="search"
            className="search-box"
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <div className="image-icon">
            <img
              src={Images?.searchIcon}
              alt="No-Image"
              style={{ width: "100%", height: "100%" }}
            />
          </div>
        </div>
        <div className="download-icon">
          <img
            src={Images?.downloadIcon}
            width="100%"
            height="100%"
            alt="No-Image"
            onClick={() => {
              downloadExcel();
            }}
          />
        </div>
      </div>

     
      <div className="view-customer-table-container mx-3 mt-3">
        <div className="view-customer-table-block">
          <table className="studetailstable" cellPadding="10px">
            <thead>
              {headerGroups?.map((headerGroup) => (
                <tr {...headerGroup?.getHeaderGroupProps()}>
                  {headerGroup?.headers?.map((column) => (
                    <th
                      className="viewcustomerheader"
                      {...column?.getHeaderProps()}
                    >
                      {column?.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.length > 0 ? (
                rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td
                          className="viewcustomerdata"
                          {...cell.getCellProps()}
                        >
                          {cell.render("Cell")}
                        </td>
                      ))}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td
                    colSpan={columns.length}
                    style={{ textAlign: "center", padding: "10px" }}
                  >
                    No records found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <PopupModal
        isOpen={isOpen}
        onClose={closeModal}
        popupState={popupState}
        setPopupState={setPopupState}
        deleteHandler={deletetogglePopup}
        isLoading={isLoading}
        message="STUDENT HAS BEEN DELETED SUCCESSFULLY"
      />
    </>
  );
}
