// import { useState } from "react";
import { React, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

export default function Sidebar() {
  const location = useLocation();
  console.log("locationkey", location.pathname);
  const employeeRole = useSelector((state) => state.employeeDetails);
  console.log("ROLEKKK", employeeRole?.role)

  return (
    <div
      className="d-flex flex-column vh-100   pt-5"
      style={{ backgroundColor: " #FFF6EA" }}
    >
      {/* <p>Current URL: {location.pathname}</p> */}
      {
        employeeRole?.role == "superAdmin" ?
          <div className="sidecontainer">
            <ul
              className="nav nav-pills flex-column mb-auto pt-3"
              style={{ backgroundColor: " #FFF6EA" }}
            >
              <div className="sideincontainer">
                <li
                  // onClick={handleClick}
                  className={`sidelistitem ${location.pathname === "/" ? "--borderactive" : ""
                    }`}
                >
                  <Link
                    to="/"
                    className={`nav-link ${location.pathname === "/"
                      ? "nav-link-active"
                      : "nav-link-inactive"
                      }`}
                  >
                    New Employee
                  </Link>
                </li>
              </div>
              <div className="sideincontainer">
                <li
                  className={`sidelistitem ${location.pathname === "/viewemployeeTable"
                    ? "--borderactive"
                    : ""
                    }`}
                >
                  <Link
                    to="/viewemployeeTable"
                    className={`nav-link ${location.pathname === "/viewemployeeTable"
                      ? "nav-link-active"
                      : "nav-link-inactive"
                      }`}
                  >
                    View Employee
                  </Link>
                </li>
              </div>
              <div className="sideincontainer">
                <li
                  className={`sidelistitem ${location.pathname === "/UploadPayslip" ? "--borderactive" : ""
                    }`}
                >
                  <Link
                    to="/UploadPayslip"
                    className={`nav-link ${location.pathname === "/UploadPayslip"
                      ? "nav-link-active"
                      : "nav-link-inactive"
                      }`}
                  >
                    Upload Payslip
                  </Link>
                </li>
              </div>
              <div className="sideincontainer">
                <li
                  className={`sidelistitem ${location.pathname === "/DownloadPayslip" ? "--borderactive" : ""
                    }`}
                >
                  <Link
                    to="/DownloadPayslip"
                    className={`nav-link ${location.pathname === "/DownloadPayslip"
                      ? "nav-link-active"
                      : "nav-link-inactive"
                      }`}
                  >
                    Download Payslip
                  </Link>
                </li>
              </div>
              <div className="sideincontainer">
                <li
                  className={`sidelistitem ${location.pathname === "/AddCustomer" ? "--borderactive" : ""
                    }`}
                >
                  <Link
                    to="/AddCustomer"
                    className={`nav-link ${location.pathname === "/AddCustomer"
                      ? "nav-link-active"
                      : "nav-link-inactive"
                      }`}
                  >
                    Add Customer
                  </Link>
                </li>
              </div>
              <div className="sideincontainer">
                <li
                  className={`sidelistitem ${location.pathname === "/ViewCustomer" ? "--borderactive" : ""
                    }`}
                >
                  <Link
                    to="/ViewCustomer"
                    className={`nav-link ${location.pathname === "/ViewCustomer"
                      ? "nav-link-active"
                      : "nav-link-inactive"
                      }`}
                  >
                    View Customer
                  </Link>
                </li>
              </div>
              <div className="sideincontainer">
                <li
                  className={`sidelistitem ${location.pathname === "/AddSales" ? "--borderactive" : ""
                    }`}
                >
                  <Link
                    to="/AddSales"
                    className={`nav-link ${location.pathname === "/AddSales"
                      ? "nav-link-active"
                      : "nav-link-inactive"
                      }`}
                  >
                    Add Sales
                  </Link>
                </li>
              </div>
              <div className="sideincontainer">
                <li
                  className={`sidelistitem ${location.pathname === "/viewsales" ? "--borderactive" : ""
                    }`}
                >
                  <Link
                    to="/viewsales"
                    className={`nav-link ${location.pathname === "/viewsales"
                      ? "nav-link-active"
                      : "nav-link-inactive"
                      }`}
                  >
                    View Sales
                  </Link>
                </li>
              </div>
              {/* <div className="sideincontainer">
            <li
              className={`sidelistitem ${
                location.pathname === "/downloadQuotation"
                  ? "--borderactive"
                  : ""
              }`}
            >
              <Link
                to="/DownloadQuotation"
                className={`nav-link ${
                  location.pathname === "/downloadQuotation"
                    ? "nav-link-active"
                    : "nav-link-inactive"
                }`}
              >
                Download Quotation
              </Link>
            </li>
          </div> */}
              <div className="sideincontainer">
                <li
                  className={`sidelistitem ${location.pathname === "/ViewQuotation" ? "--borderactive" : ""
                    }`}
                >
                  <Link
                    to="/ViewQuotation"
                    className={`nav-link ${location.pathname === "/ViewQuotation"
                      ? "nav-link-active"
                      : "nav-link-inactive"
                      }`}
                  >
                    View Quotation List
                  </Link>
                </li>
              </div>
              <div className="sideincontainer">
                <li
                  className={`sidelistitem ${location.pathname === "/NewQuotation" ? "--borderactive" : ""
                    }`}
                >
                  <Link
                    to="/NewQuotation"
                    className={`nav-link ${location.pathname === "/NewQuotation"
                      ? "nav-link-active"
                      : "nav-link-inactive"
                      }`}
                  >
                    New Quotation
                  </Link>
                </li>
              </div>
              <div className="sideincontainer">
                <li
                  className={`sidelistitem ${location.pathname === "/StudentFormPg" ? "--borderactive" : ""
                    }`}
                >
                  <Link
                    to="/StudentFormPg"
                    className={`nav-link ${location.pathname === "/StudentFormPg"
                      ? "nav-link-active"
                      : "nav-link-inactive"
                      }`}
                  >
                    Add Student
                  </Link>
                </li>
              </div>
              <div className="sideincontainer">
                <li
                  className={`sidelistitem ${location.pathname === "/StudentViewTable  "
                    ? "--borderactive"
                    : ""
                    }`}
                >
                  <Link
                    to="/StudentViewTable"
                    className={`nav-link ${location.pathname === "/StudentViewTable"
                      ? "nav-link-active"
                      : "nav-link-inactive"
                      }`}
                  >
                    View Student
                  </Link>
                </li>
              </div>
              <div className="sideincontainer">
                <li
                  className={`sidelistitem ${location.pathname === "/CourseDetails" ? "--borderactive" : ""
                    }`}
                >
                  <Link
                    to="/CourseDetails"
                    className={`nav-link ${location.pathname === "/CourseDetails"
                      ? "nav-link-active"
                      : "nav-link-inactive"
                      }`}
                  >
                    Course Details
                  </Link>
                </li>
              </div>
            </ul>
          </div>
          : employeeRole?.role == "admin" || employeeRole?.role == "sales" ?
            <div className="sidecontainer">
              <ul
                className="nav nav-pills flex-column mb-auto pt-3"
                style={{ backgroundColor: " #FFF6EA" }}
              >
                {/* <div className="sideincontainer">
                  <li
                    // onClick={handleClick}
                    className={`sidelistitem ${location.pathname === "/" ? "--borderactive" : ""
                      }`}
                  >
                    <Link
                      to="/"
                      className={`nav-link ${location.pathname === "/"
                        ? "nav-link-active"
                        : "nav-link-inactive"
                        }`}
                    >
                      New Employee
                    </Link>
                  </li>
                </div> */}
                {/* <div className="sideincontainer">
                  <li
                    className={`sidelistitem ${location.pathname === "/viewemployeeTable"
                      ? "--borderactive"
                      : ""
                      }`}
                  >
                    <Link
                      to="/viewemployeeTable"
                      className={`nav-link ${location.pathname === "/viewemployeeTable"
                        ? "nav-link-active"
                        : "nav-link-inactive"
                        }`}
                    >
                      View Employee
                    </Link>
                  </li>
                </div> */}
                <div className="sideincontainer">
                  <li
                    className={`sidelistitem ${location.pathname === "/" ? "--borderactive" : ""
                      }`}
                  >
                    <Link
                      to="/"
                      className={`nav-link ${location.pathname === "/"
                        ? "nav-link-active"
                        : "nav-link-inactive"
                        }`}
                    >
                      Upload Payslip
                    </Link>
                  </li>
                </div>
                <div className="sideincontainer">
                  <li
                    className={`sidelistitem ${location.pathname === "/DownloadPayslip" ? "--borderactive" : ""
                      }`}
                  >
                    <Link
                      to="/DownloadPayslip"
                      className={`nav-link ${location.pathname === "/DownloadPayslip"
                        ? "nav-link-active"
                        : "nav-link-inactive"
                        }`}
                    >
                      Download Payslip
                    </Link>
                  </li>
                </div>
                <div className="sideincontainer">
                  <li
                    className={`sidelistitem ${location.pathname === "/AddCustomer" ? "--borderactive" : ""
                      }`}
                  >
                    <Link
                      to="/AddCustomer"
                      className={`nav-link ${location.pathname === "/AddCustomer"
                        ? "nav-link-active"
                        : "nav-link-inactive"
                        }`}
                    >
                      Add Customer
                    </Link>
                  </li>
                </div>
                <div className="sideincontainer">
                  <li
                    className={`sidelistitem ${location.pathname === "/ViewCustomer" ? "--borderactive" : ""
                      }`}
                  >
                    <Link
                      to="/ViewCustomer"
                      className={`nav-link ${location.pathname === "/ViewCustomer"
                        ? "nav-link-active"
                        : "nav-link-inactive"
                        }`}
                    >
                      View Customer
                    </Link>
                  </li>
                </div>
                <div className="sideincontainer">
                  <li
                    className={`sidelistitem ${location.pathname === "/AddSales" ? "--borderactive" : ""
                      }`}
                  >
                    <Link
                      to="/AddSales"
                      className={`nav-link ${location.pathname === "/AddSales"
                        ? "nav-link-active"
                        : "nav-link-inactive"
                        }`}
                    >
                      Add Sales
                    </Link>
                  </li>
                </div>
                <div className="sideincontainer">
                  <li
                    className={`sidelistitem ${location.pathname === "/viewsales" ? "--borderactive" : ""
                      }`}
                  >
                    <Link
                      to="/viewsales"
                      className={`nav-link ${location.pathname === "/viewsales"
                        ? "nav-link-active"
                        : "nav-link-inactive"
                        }`}
                    >
                      View Sales
                    </Link>
                  </li>
                </div>

                <div className="sideincontainer">
                  <li
                    className={`sidelistitem ${location.pathname === "/ViewQuotation" ? "--borderactive" : ""
                      }`}
                  >
                    <Link
                      to="/ViewQuotation"
                      className={`nav-link ${location.pathname === "/ViewQuotation"
                        ? "nav-link-active"
                        : "nav-link-inactive"
                        }`}
                    >
                      View Quotation List
                    </Link>
                  </li>
                </div>
                <div className="sideincontainer">
                  <li
                    className={`sidelistitem ${location.pathname === "/NewQuotation" ? "--borderactive" : ""
                      }`}
                  >
                    <Link
                      to="/NewQuotation"
                      className={`nav-link ${location.pathname === "/NewQuotation"
                        ? "nav-link-active"
                        : "nav-link-inactive"
                        }`}
                    >
                      New Quotation
                    </Link>
                  </li>
                </div>
                <div className="sideincontainer">
                  <li
                    className={`sidelistitem ${location.pathname === "/StudentFormPg" ? "--borderactive" : ""
                      }`}
                  >
                    <Link
                      to="/StudentFormPg"
                      className={`nav-link ${location.pathname === "/StudentFormPg"
                        ? "nav-link-active"
                        : "nav-link-inactive"
                        }`}
                    >
                      Add Student
                    </Link>
                  </li>
                </div>
                <div className="sideincontainer">
                  <li
                    className={`sidelistitem ${location.pathname === "/StudentViewTable  "
                      ? "--borderactive"
                      : ""
                      }`}
                  >
                    <Link
                      to="/StudentViewTable"
                      className={`nav-link ${location.pathname === "/StudentViewTable"
                        ? "nav-link-active"
                        : "nav-link-inactive"
                        }`}
                    >
                      View Student
                    </Link>
                  </li>
                </div>
                <div className="sideincontainer">
                  <li
                    className={`sidelistitem ${location.pathname === "/CourseDetails" ? "--borderactive" : ""
                      }`}
                  >
                    <Link
                      to="/CourseDetails"
                      className={`nav-link ${location.pathname === "/CourseDetails"
                        ? "nav-link-active"
                        : "nav-link-inactive"
                        }`}
                    >
                      Course Details
                    </Link>
                  </li>
                </div>
              </ul>
            </div>
            : <div className="sidecontainer">
              <ul
                className="nav nav-pills flex-column mb-auto pt-3"
                style={{ backgroundColor: " #FFF6EA" }}
              >
                {/* <div className="sideincontainer">
                  <li
                    // onClick={handleClick}
                    className={`sidelistitem ${location.pathname === "/" ? "--borderactive" : ""
                      }`}
                  >
                    <Link
                      to="/"
                      className={`nav-link ${location.pathname === "/"
                        ? "nav-link-active"
                        : "nav-link-inactive"
                        }`}
                    >
                      New Employee
                    </Link>
                  </li>
                </div> */}
                {/* <div className="sideincontainer">
                  <li
                    className={`sidelistitem ${location.pathname === "/viewemployeeTable"
                      ? "--borderactive"
                      : ""
                      }`}
                  >
                    <Link
                      to="/viewemployeeTable"
                      className={`nav-link ${location.pathname === "/viewemployeeTable"
                        ? "nav-link-active"
                        : "nav-link-inactive"
                        }`}
                    >
                      View Employee
                    </Link>
                  </li>
                </div> */}
                {/* <div className="sideincontainer">
                  <li
                    className={`sidelistitem ${location.pathname === "/" ? "--borderactive" : ""
                      }`}
                  >
                    <Link
                      to="/UploadPayslip"
                      className={`nav-link ${location.pathname === "/UploadPayslip"
                        ? "nav-link-active"
                        : "nav-link-inactive"
                        }`}
                    >
                      Upload Payslip
                    </Link>
                  </li>
                </div> */}
                <div className="sideincontainer">
                  <li
                    className={`sidelistitem ${location.pathname === "/" ? "--borderactive" : ""
                      }`}
                  >
                    <Link
                      to="/"
                      className={`nav-link ${location.pathname === "/"
                        ? "nav-link-active"
                        : "nav-link-inactive"
                        }`}
                    >
                      Download Payslip
                    </Link>
                  </li>
                </div>
                {/* <div className="sideincontainer">
                  <li
                    className={`sidelistitem ${location.pathname === "/AddCustomer" ? "--borderactive" : ""
                      }`}
                  >
                    <Link
                      to="/AddCustomer"
                      className={`nav-link ${location.pathname === "/AddCustomer"
                        ? "nav-link-active"
                        : "nav-link-inactive"
                        }`}
                    >
                      Add Customer
                    </Link>
                  </li>
                </div>
                <div className="sideincontainer">
                  <li
                    className={`sidelistitem ${location.pathname === "/ViewCustomer" ? "--borderactive" : ""
                      }`}
                  >
                    <Link
                      to="/ViewCustomer"
                      className={`nav-link ${location.pathname === "/ViewCustomer"
                        ? "nav-link-active"
                        : "nav-link-inactive"
                        }`}
                    >
                      View Customer
                    </Link>
                  </li>
                </div>
                <div className="sideincontainer">
                  <li
                    className={`sidelistitem ${location.pathname === "/AddSales" ? "--borderactive" : ""
                      }`}
                  >
                    <Link
                      to="/AddSales"
                      className={`nav-link ${location.pathname === "/AddSales"
                        ? "nav-link-active"
                        : "nav-link-inactive"
                        }`}
                    >
                      Add Sales
                    </Link>
                  </li>
                </div>
                <div className="sideincontainer">
                  <li
                    className={`sidelistitem ${location.pathname === "/viewsales" ? "--borderactive" : ""
                      }`}
                  >
                    <Link
                      to="/viewsales"
                      className={`nav-link ${location.pathname === "/viewsales"
                        ? "nav-link-active"
                        : "nav-link-inactive"
                        }`}
                    >
                      View Sales
                    </Link>
                  </li>
                </div>

                <div className="sideincontainer">
                  <li
                    className={`sidelistitem ${location.pathname === "/ViewQuotation" ? "--borderactive" : ""
                      }`}
                  >
                    <Link
                      to="/ViewQuotation"
                      className={`nav-link ${location.pathname === "/ViewQuotation"
                        ? "nav-link-active"
                        : "nav-link-inactive"
                        }`}
                    >
                      View Quotation List
                    </Link>
                  </li>
                </div>
                <div className="sideincontainer">
                  <li
                    className={`sidelistitem ${location.pathname === "/NewQuotation" ? "--borderactive" : ""
                      }`}
                  >
                    <Link
                      to="/NewQuotation"
                      className={`nav-link ${location.pathname === "/NewQuotation"
                        ? "nav-link-active"
                        : "nav-link-inactive"
                        }`}
                    >
                      New Quotation
                    </Link>
                  </li>
                </div>
                <div className="sideincontainer">
                  <li
                    className={`sidelistitem ${location.pathname === "/StudentFormPg" ? "--borderactive" : ""
                      }`}
                  >
                    <Link
                      to="/StudentFormPg"
                      className={`nav-link ${location.pathname === "/StudentFormPg"
                        ? "nav-link-active"
                        : "nav-link-inactive"
                        }`}
                    >
                      Add Student
                    </Link>
                  </li>
                </div>
                <div className="sideincontainer">
                  <li
                    className={`sidelistitem ${location.pathname === "/StudentViewTable  "
                      ? "--borderactive"
                      : ""
                      }`}
                  >
                    <Link
                      to="/StudentViewTable"
                      className={`nav-link ${location.pathname === "/StudentViewTable"
                        ? "nav-link-active"
                        : "nav-link-inactive"
                        }`}
                    >
                      View Student
                    </Link>
                  </li>
                </div>
                <div className="sideincontainer">
                  <li
                    className={`sidelistitem ${location.pathname === "/CourseDetails" ? "--borderactive" : ""
                      }`}
                  >
                    <Link
                      to="/CourseDetails"
                      className={`nav-link ${location.pathname === "/CourseDetails"
                        ? "nav-link-active"
                        : "nav-link-inactive"
                        }`}
                    >
                      Course Details
                    </Link>
                  </li>
                </div> */}
              </ul>
            </div>
      }
    </div>
  );
}
