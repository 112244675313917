import React, { useEffect, useState } from "react";
import { set, useForm, Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import { useMutation } from "react-query";
import axios from "axios";
import { useLocation, useNavigate } from "react-router";
import moment from "moment";
import PopupModal from "../popupmodal/PopupModal";

export default function CustomerCreationForm() {
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors, isSubmitted },
  } = useForm();
  const [startDate, setStartDate] = useState(new Date());
  const navigate = useNavigate();
  const { state } = useLocation();


  const [isOpen, setIsOpen] = useState(false);
  const [popupState, setPopupState] = useState({
    addData: false,
  });

  // Function to close the modal
  const closeModal = () => {
    setIsOpen(false);
    setPopupState({
      addData: false,
   
    });
  };

  const handleNavigate = () => {
    navigate("/viewquotation");
    setIsOpen(false);
    setPopupState({
      addData: false,
    });
  };

  // useEffect(() => {
  //   if (state && state) {
  //     setValue("customerId", state?.customerId);
  //     setValue("clientName", state?.clientName);
  //     setValue("companyName", state?.companyName);
  //     setValue("designation", state?.designation);
  //     setValue("phoneNumber", state?.phoneNumber);
  //     setValue("base", state?.base);
  //     setValue("need", state?.need);
  //     setValue("telecallerName", state?.telecallerName);
  //     setValue("description", state?.description);
  //     setValue("assignTo", state?.assignTo);
  //     setValue("telecallerMobNo", state?.telecallerMobNo);
  //     // setValue("")
  //   }
  // }, [state, setValue]);

  useEffect(() => {
    if (state && state?.length > 0) {
      state?.forEach((ele) => {
        Object.entries(ele || {}).forEach(([key, value]) => {
          setValue(key, value || "");
        });
      });
    }
  }, [state, setValue]);

  const { mutate, isLoading, isError, isSuccess } = useMutation(
    async (postData) => {
      try {
        const response = await axios.post(
          `https://api.2kvirtualworld.com/EMS/customers`,
          postData
        );
        return response;
      } catch (error) {
        if (error?.response?.status === 500) {
          alert(
            "Oops! Something went wrong on our end. Please try again later."
          );
        } else if (error?.response) {
          const { data } = error?.response;
          console.log("Error Response:", data);

          // setErrorMessage(data?.message || "An error occurred");
          // setErrorPopUp(1);
        } else {
          console.log("Unexpected Error:", error?.message);
          alert("An unexpected error occurred. Please try again.");
        }

        throw error;
      }
    },
    {
      onSuccess: () => {
        // navigate("/ViewCustomer");
        setIsOpen(true);
        setPopupState({
          addData: true,
        });
        // console.log("Data added successfully...");
        // setRegisterPopup(1);
      },
      onError: (error) => {
        console.log("Error occurred in mutation:", error);
      },
    }
  );
  // const UpdateFormValue = useMutation(
  //   (postData) =>
  //     axios.put(
  //       `https://api.2kvirtualworld.com/EMS/customers/${state?.customerId}`,
  //       postData
  //     ),
  //   {
  //     onSuccess: (data) => {
  //       // alert("successfully updated.");
  //       navigate("/ViewCustomer");
  //       // setUpdateOpen("USER UPDATED");
  //     },
  //     onError: (error) => {
  //       // alert(error)
  //       console("Mutation failed:", error);
  //     },
  //   }
  // );

  const UpdateFormValue = useMutation(
    async (postData) => {
      try {
        const response = await axios.put(
          `https://api.2kvirtualworld.com/EMS/customers/${state[0]?.customerId}`,
          postData
        );
        return response.data;
      } catch (error) {
        console.error("Error occurred while updating the student:", error);
        throw error;
      }
    },
    {
      onSuccess: (data) => {
        console.log("User updated successfully:", data);
        // navigate("/ViewCustomer");
        // Perform additional actions on success if needed
        // Example: setPopupOpen(1);
        setIsOpen(true);
        setPopupState({
          addData: true,
        });
      },
      onError: (error) => {
        console.error("Mutation failed:", error);
        // Handle any additional error actions like displaying an error message
      },
    }
  );
  const onSubmit = (data) => {
    let formData = {
      clientName: data?.clientName,
      companyName: data?.companyName,
      designation: data?.designation,
      phoneNumber: data?.phoneNumber,
      base: data?.base,
      dateOfEnquiry: moment(data?.dateOfEnquiry).format("YYYY-MM-DD"),
      need: data?.need,
      telecallerName: data?.telecallerName,
      description: data?.description,
      assignTo: data?.assignTo,
      otherNeeds: data?.otherNeeds,
      otherSource: data?.otherSource,
      telecallerMobNo: data?.telecallerMobNo,
    };
    if (state && state[0]?.customerId) {
      UpdateFormValue.mutate(formData);
    } else if (formData) {
      mutate(formData);
    }
    // console.log("formData", formData);
  };

  return (
    <>
      <div className="customercreationbg">
        <div className="customercreationFormheader">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="customercontainer">
              <div className="customercreationheader">
                <div>
                  <label className="custlabel">Customer Id</label>
                  <input
                    className="custinput"
                    type="text"
                    placeholder="Customer Id"
                    {...register("customerId")}
                  ></input>
                </div>
                <div>
                  <label className="custlabel">Company Name</label>
                  <input
                    type="text"
                    className="custinput"
                    placeholder="Company Name"
                    {...register("companyName")}
                  ></input>
                </div>
                <div>
                  <label className="custlabel">Phone Number</label>
                  <input
                    type="text"
                    className="custinput"
                    placeholder="Phone Number"
                    {...register("phoneNumber", {
                      required: "Phone number is required",
                      pattern: {
                        value: /^[0-9]{10}$/,
                      },
                    })}
                  ></input>
                  {isSubmitted && errors.phoneNumber && (
                    <p>Phone number must be exactly 10 digits</p>
                  )}
                </div>

                <div>
                  <label className="custlabel">Date of Enquiry</label>
                  {/* <DatePicker
                    {...register("dateOfEnquiry")}
                    wrapperClassName="custdate"
                    className="custdate"
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    // style={{ display: "flex", flexDirection: "column" ,height:'50px',width:'400px',background:" #FFF6EA",}}
                  /> */}
                  <Controller
                    name="dateOfEnquiry"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        selected={
                          field.value ? moment(field.value).toDate() : null
                        }
                        onChange={(date) =>
                          field.onChange(moment(date).format("YYYY-MM-DD"))
                        }
                        dateFormat="yyyy/MM/dd"
                        placeholderText="30-12-2002"
                        showYearDropdown
                        className="custdate"
                      />
                    )}
                  />
                </div>
                <div>
                  <label className="custlabel">Need</label>

                  <select className="custinput" {...register("need")}>
                    <option value="" disabled selected>
                      Need
                    </option>
                    <option value="Web Application">Web Application</option>
                    <option value="Mobile Application">
                      Mobile Application
                    </option>
                    <option value="Website">Website</option>
                    <option value="Stock Management">Stock Management</option>
                  </select>
                  <label className="custlabel"></label>
                  <textarea
                    {...register("otherNeeds")}
                    type="text"
                    placeholder="*non mandatory"
                    className="custominput"
                  ></textarea>
                </div>
                <div>
                  <label className="custlabel">Description</label>
                  <textarea
                    type="text"
                    className="customdescription"
                    placeholder="Description"
                    {...register("description")}
                  ></textarea>
                </div>
              </div>
              <div className="customercreationheaderright">
                <div>
                  <label className="custlabel">Client Name</label>
                  <input
                    type="text"
                    className="custinput"
                    placeholder="Client Name"
                    {...register("clientName")}
                  ></input>
                </div>
                <div>
                  <label className="custlabel">Designation</label>
                  <input
                    type="text"
                    className="custinput"
                    placeholder="Designation"
                    {...register("designation")}
                  ></input>
                </div>
                <div className="customselectright">
                  <label className="custlabel">Source</label>
                  <select className="custinput" {...register("base")}>
                    <option value="" disabled selected>
                      Source
                    </option>
                    <option value="Google">Google</option>
                    <option value="Youtube">Youtube</option>
                    <option value="Just Dial">Just Dial</option>
                  </select>
                  <textarea
                    type="text"
                    placeholder="*non mandatory"
                    className="custominput"
                    {...register("otherSource")}
                  ></textarea>
                </div>

                <div>
                  <label className="custlabel">Tele Caller Name</label>
                  <input
                    type="text"
                    className="custinput"
                    placeholder="Tele Caller Name"
                    {...register("telecallerName")}
                  ></input>
                </div>

                <div className="custlable">
                  <label className="custlabel">Tele Caller Mobile NO</label>
                  <input
                    type="text"
                    className="custinput"
                    placeholder="Tele Caller Mobile No"
                    {...register("telecallerMobNo", {
                      required: "Phone number is required",
                      pattern: {
                        value: /^[0-9]{10}$/,
                      },
                    })}
                  ></input>
                  {isSubmitted && errors.telecallermobileno && (
                    <p>Phone number must be exactly 10 digits</p>
                  )}
                </div>
                <div>
                  <label className="custlabel">Assign To</label>

                  <select className="custinput" {...register("assignTo")}>
                    <option value="" disabled selected>
                      Assign To
                    </option>
                    <option value="User1">User1</option>
                    <option value="User2">User2</option>
                    <option value="User3">User3</option>
                  </select>
                </div>

                <div className="btn-group dropend">
                  <button type="submit" className="btn btn-primary btn-custom">
                    {/* <i class="fa fa-spinner fa-spin"></i> */}
                    {isLoading ? (
                      <i class="fa fa-spinner fa-spin"></i>
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <PopupModal
        isOpen={isOpen}
        onClose={closeModal}
        popupState={popupState}
        message="NEW CUSTOMER HAS BEEN ADDED SUCCESSFULLY"
        onDone={handleNavigate}
        updateMessage="CUSTOMER HAS BEEN UPDATED SUCCESSFULLY"
        stateID={state && state[0]?.customerId}
      />
    </>
  );
}
