import React, { useState } from "react";
import Images from "../../../asstes";
import { useNavigate } from "react-router";
import axios from "axios";

const PopupModal = ({
  isOpen,
  onClose,
  popupState,
  setPopupState,
  isLoading,
  message,
  updateMessage,
  onDone,
  deleteHandler,
  stateID,
}) => {
  // console.log("STATE", stateID);
  if (!isOpen) return null;

  return (
    <>
      <div className="modal-overlay" onClick={onClose}>
        {popupState && popupState?.addData && (
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <div className="row d-flex flex-column gap-3">
              <div
                className="col-lg-12"
                style={{ borderBottom: "2px solid #7A1CAC" }}
              >
                <h6 className="popup-head">2K EMPLOYEE MANAGEMENT SYSTEM</h6>
                <div
                  style={{
                    height: "24px",
                    width: "24px",
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={Images?.close}
                    style={{ width: "100%", height: "100%" }}
                    alt="No-image"
                    onClick={onClose}
                  />
                </div>
              </div>
              <div className="col-lg-12 d-flex justify-content-center">
                <div style={{ height: "50px", width: "50px" }}>
                  <img
                    src={Images?.errorIcon}
                    style={{ width: "100%", height: "100%" }}
                    alt="No-image"
                  />
                </div>
              </div>

              <h5 className="popup-head text-center">
                {stateID && stateID ? updateMessage : message}
              </h5>
              <div className="col-lg-12 d-flex justify-content-center">
                <button className="close-modal-btn" onClick={onDone}>
                  Done
                </button>
              </div>
            </div>
          </div>
        )}
        {popupState && popupState?.removeConfirm && (
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <div className="row d-flex flex-column gap-3">
              <div
                className="col-lg-12"
                style={{ borderBottom: "2px solid #7A1CAC" }}
              >
                <h6 className="popup-head">2K EMPLOYEE MANAGEMENT SYSTEM</h6>
                <div
                  style={{
                    height: "24px",
                    width: "24px",
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={Images?.close}
                    style={{ width: "100%", height: "100%" }}
                    alt="No-image"
                    onClick={onClose}
                  />
                </div>
              </div>
              <div className="col-lg-12 d-flex justify-content-center">
                <div style={{ height: "50px", width: "50px" }}>
                  <img
                    src={Images?.errorIcon}
                    style={{ width: "100%", height: "100%" }}
                    alt="No-image"
                  />
                </div>
              </div>

              <h5 className="popup-head text-center">ARE YOU SURE ?</h5>
              <div className="col-lg-12 d-flex justify-content-center gap-3">
                <button
                  className="close-modal-btn"
                  onClick={async () => {
                    if (popupState?.deleteId) {
                      await deleteHandler(popupState.deleteId); // Trigger the delete handler passed from parent
                      setPopupState({
                        removeConfirm: false, // Hide the confirmation modal
                        removeSucess: true, // Indicate success
                      });
                    }
                  }}
                >
                  {" "}
                  {isLoading ? <i class="fa fa-spinner fa-spin"></i> : " Yes"}
                </button>
                <button className="close-modal-btn" onClick={onClose}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
        {popupState && popupState?.removeSucess && (
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <div className="row d-flex flex-column gap-3">
              <div
                className="col-lg-12"
                style={{ borderBottom: "2px solid #7A1CAC" }}
              >
                <h6 className="popup-head">2K EMPLOYEE MANAGEMENT SYSTEM</h6>
                <div
                  style={{
                    height: "24px",
                    width: "24px",
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={Images?.close}
                    style={{ width: "100%", height: "100%" }}
                    alt="No-image"
                    onClick={onClose}
                  />
                </div>
              </div>
              <div className="col-lg-12 d-flex justify-content-center">
                <div style={{ height: "50px", width: "50px" }}>
                  <img
                    src={Images?.errorIcon}
                    style={{ width: "100%", height: "100%" }}
                    alt="No-image"
                  />
                </div>
              </div>

              <h5 className="popup-head text-center"> {message}</h5>

              <div className="col-lg-12 d-flex justify-content-center">
                <button
                  className="close-modal-btn"
                  onClick={() => {
                    onClose();
                  }}
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PopupModal;
