import React, { useState } from "react";
import Images from "../../../asstes";
import Logo from "../../../asstes/png/logo.png"
import { useLocation } from "react-router";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import moment from "moment";
const DownloadQuotation = () => {
  const { state } = useLocation();
  console.log("DDFDDDDFDD", state);
  const [isLoading, setIsLoading] = useState(false);
  const generatePDF = async () => {
    setIsLoading(true)
    const doc = new jsPDF("p", "mm", "a4"); // A4 size
    const options = { scale: 3 }; // Higher scale for better quality
    const a4Width = 210; // A4 width in mm
    const a4Height = 297; // A4 height in mm
    const contentWidth = 190; // Content width in mm (with 10mm margin)

    // Capture Page 1
    const page1 = document.getElementById("sheet1");
    const page1Canvas = await html2canvas(page1, options);
    const page1ImgData = page1Canvas.toDataURL("image/png");
    const page1Ratio = page1Canvas.width / page1Canvas.height;
    const page1Height = contentWidth / page1Ratio; // Scale height to fit width
    doc.addImage(page1ImgData, "PNG", 10, 10, contentWidth, page1Height);

    // Capture Page 2
    const page2 = document.getElementById("sheet2");
    const page2Canvas = await html2canvas(page2, options);
    const page2ImgData = page2Canvas.toDataURL("image/png");
    const page2Ratio = page2Canvas.width / page2Canvas.height;
    const page2Height = contentWidth / page2Ratio;
    doc.addPage();
    doc.addImage(page2ImgData, "PNG", 10, 10, contentWidth, page2Height);

    // Capture Page 3
    const page3 = document.getElementById("sheet3");
    const page3Canvas = await html2canvas(page3, options);
    const page3ImgData = page3Canvas.toDataURL("image/png");
    const page3Ratio = page3Canvas.width / page3Canvas.height;
    const page3Height = contentWidth / page3Ratio;
    doc.addPage();
    doc.addImage(page3ImgData, "PNG", 10, 10, contentWidth, page3Height);

    // Save the PDF
    doc.save("combined_a4.pdf");
    setIsLoading(false)
  };
  return (
    <>
      <div className="downloadNotationMainSection" style={{ position: "relative" }}>
        <button onClick={generatePDF} className="btn-grad" disabled={isLoading} style={{
          // marginTop: "20px", padding: "10px 20px", cursor: "pointer",
          position: "fixed",
          // display: flex,
          bottom: "25px",
          right: "20px",
          zIndex: 99,
          outline: "none"
          , border: "none",
          // background: "#adadad",
          borderRadius: "5px",
          // color: "#fff", backgroundImage: "linear - gradient(to right, #9D50BB 0 %, #6E48AA  51 %, #9D50BB  100 %)",
          margin: "10px",
          padding: "15px 45px",
          textAlign: "center",
          // textTransform: uppercase;
          transition: "0.5s",
          backgroundSize: "200% auto",
          color: "white",
          boxShadow: " 0 0 20px #eee",
          borderRadius: "10px",
          display: "block"
        }}>
          {isLoading ? "Loading..." : "Download Payslip"}
        </button>
        <div className="quotaionMainSection px-5" id="sheet1">
          <div
            className="row pt-3 pb-1"
            style={{ borderBottom: "6px solid brown" }}
          >
            <div className="col-lg-3 d-flex justify-content-center align-items-center">
              <div style={{ width: "75%", height: "75%" }}>
                <img src={Logo} alt="No-Image" style={{ width: "100%", height: "100%" }} />
              </div>
            </div>
            <div className="col-lg-7 text-center">
              <h5 className="download-quotation-head">2KVIRTUALWORLD</h5>
              <p className="download-quotation-title">
                (Group of DVK Virtual World)
              </p>
              <p className="download-quotation-title">
                #315 Bharathi Street, Muttukadu, Chennai-603112
              </p>
              <p className="download-quotation-title">
                Mobile No : 8667079176, 9092540241
              </p>
              <p className="download-quotation-title">
                Mail ID : info@2kvirtualworld.com
              </p>
              <p className="download-quotation-title">
                GSTIN : 33CNWPV3228G1ZI
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 text-end py-2 quote-details">
              <div className="detail">
                <span className="label">Quote No</span>
                <span className="value_dd">: {state[0].customerId}</span>
              </div>
              <div className="detail">
                <span className="label">Customer ID</span>
                {/* <span className="label">:</span> */}
                <span className="value_dd">: {state[0].customerId}</span>
              </div>
              <div className="detail">
                <span className="label">Mobile No</span>
                <span className="value_dd">: {state[0].phoneNumber}</span>
              </div>
              <div className="detail">
                <span className="label">Date</span>
                <span className="value_dd">: {moment(state[0].createdDate).format("DD-MM-YYYY")}</span>
              </div>
              <div className="detail">
                <span className="label">Expiration Date</span>
                <span className="value_dd">: {moment(state[0].createdDate).format("DD-MM-YYYY")}</span>
              </div>
              {/* <p className="quotation-text-head">
                <span className="quotation-text">Quote No</span>{" "}
                <span className="quotation-text-value"> : {state[0]?.customerId}</span>
              </p>
              <p className="quotation-text-head">
                <span className="quotation-text">Customer ID</span>{" "}
                <span className="quotation-text-value"> : {state[0]?.customerId}</span>
              </p>
              <p className="quotation-text-head">
                <span className="quotation-text">Mobile No</span>{" "}
                <span className="quotation-text-value"> : {state[0]?.phoneNumber}</span>
              </p>
              <p className="quotation-text-head">
                <span className="quotation-text">Date</span>{" "}
                <span className="quotation-text-value"> : 29/08/2024</span>
              </p>
              <p className="quotation-text-head">
                <span className="quotation-text">Expiration Date</span>{" "}
                <span className="quotation-text-value"> : 28/09/2024</span>
              </p> */}
            </div>
            <div className="col-lg-12 p-0">
              <p className="quotation-text my-1">To</p>
              <p
                className="quotation-text-content d-flex
            flex-column"
              >
                <span>{state[0]?.genderTitle}.{state[0]?.customerName}</span>
                <span>{state[0]?.companyName}, </span>
                <span>{state[0]?.flatNo}{state[0]?.street}</span>
                <span>{state[0]?.area},</span>
                <span>{state[0]?.city}-{state[0]?.pincode}.</span>
                <span>{state[0]?.state}.</span>
              </p>
            </div>
            <div className="col-lg-12 p-0 py-1">
              <p className="quotation-text-content">
                We are pleased to offer a comprehensive quotation for the
                development of for your Business. The total cost, encompassing
                Billing Software development services is ₹
              </p>
              {/* <p className="quotation-text-content">With out Server</p>
              <table className="download-table-block my-3">
                <thead>
                  <tr>
                    <th className="download-notation-head">S.NO</th>
                    <th className="download-notation-head">Description</th>
                    <th className="download-notation-head">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="download-notation-table-data">1</td>
                    <td className="download-notation-table-data">
                      Website Development{" "}
                    </td>
                    <td className="download-notation-table-data"></td>
                  </tr>
                </tbody>
              </table> */}
              <p className="quotation-text-content">With Server</p>
              <table className="download-table-block my-3">
                <thead>
                  <tr>
                    <th className="download-notation-head">S.NO</th>
                    <th className="download-notation-head">Description</th>
                    <th className="download-notation-head">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="download-notation-table-data">1</td>
                    <td className="download-notation-table-data">
                      Website Development{" "}
                    </td>
                    <td className="download-notation-table-data"></td>
                  </tr>
                  <tr>
                    <td className="download-notation-table-data">2</td>
                    <td className="download-notation-table-data">Server </td>
                    <td className="download-notation-table-data"></td>
                  </tr>
                  <tr>
                    <td className="download-notation-table-data"></td>
                    <td
                      className="download-notation-table-data"
                      style={{ fontWeight: "700", textAlign: "end" }}
                    >
                      Total
                    </td>
                    <td className="download-notation-table-data"></td>
                  </tr>
                </tbody>
              </table>
              <p className="quotation-text-content --url">
                <a href="www.2kvirtualworld.com" target="_blank">
                  www.2kvirtualworld.com{" "}
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="quotaionMainSection px-5" id="sheet2">
          <h5 className="download-notation-sub-heading pt-4">
            Payment Milestones{" "}
          </h5>
          <p className="quotation-text-para py-2">
            We follow a milestone-based payment model which match with the
            delivery milestones of the project development. The usual payment
            milestones are:{" "}
          </p>
          <p className="quotation-text-content">
            1) First Payment - 50% (Design Sign Off and Customizations)
          </p>
          <p className="quotation-text-content">
            2) Second Payment – 50% (After Demo)
          </p>
          <p className="quotation-text-content">
            * Please be aware that GST will be applied in addition to the quoted
            amount.
          </p>
          <p className="quotation-text-content">
            *Annual Maintenance Cost (AMC) ₹ it will be including domain,
            hosting, Database and server cost for both mobile and web
            applications.
          </p>
          <h5 className="download-notation-sub-heading pt-3 pb-1">
            Project Delivery Estimated Durations
          </h5>
          <p className="quotation-text-content">
            1) 0 to 30 days (Design Sign Off)
          </p>
          <p className="quotation-text-content">
            2) 31 to 60 days (Testing and Delivery)
          </p>
          <h5 className="download-notation-sub-heading pt-3 pb-1">
            Admin Flow Diagram:
          </h5>
          <div style={{ width: "40%", height: "40%", objectFit: "contain" }} className="d-flex justify-content-center align-items-center">
            <img
              src={`data:image/png;base64,${state[0]?.flowDiagram}`}
              alt="no-image"
              style={{ width: "100%", height: "100%" }}
            />
          </div>
          <p className="quotation-text-content pt-2">
            View the types of X-rays, blood tests, and physiotherapy sessions
            available. You can adjust the amounts and modify details for X-rays,
            lab tests, and physiotherapy.
          </p>
          <p className="quotation-text-content ">
            To see all details of a enter the ID and all information will be
            displayed.
          </p>
          <p className="quotation-text-content">
            <a href="www.2kvirtualworld.com" target="_blank">
              www.2kvirtualworld.com{" "}
            </a>
          </p>
        </div>

        <div className="quotaionMainSection px-5" id="sheet3">
          <h5 className="download-notation-sub-heading pt-4">
            Terms and Conditions{" "}
          </h5>
          <p className="quotation-text-para py-1">
             A demo screen will be shared based on your requirements once it
            gets acknowledged by you. No changes will be made after approval of
            the demo.{" "}
          </p>
          <p className="quotation-text-para py-1">
             User retention mail ID is required from your side if any Mail or
            SMS or payment integration process Once the project is completed,
            you can reset the password of the mail.
          </p>
          <p className="quotation-text-para py-1">
             If you drop the project in between the above duration, the amount
            will not be refundable but we will hand over the source file which
            we had done.
          </p>
          <p className="quotation-text-para py-1">
             If any additional developments in this project, cost and duration
            will different.{" "}
          </p>
          <p className="quotation-text-para py-1">
             We will fulfil your requirements and deliver the applications as
            committed within the estimated time and cost. From the second year
            onwards, if you are not under the AMC (Annual Maintenance Cost), the
            renewal and maintenance costs will be different. These costs are not
            covered in this estimate.
          </p>
          <p className="quotation-text-para py-1">
             After the project handover we will give a list of third-party
            APIs, like e.g., SMS integration, email integration what we used in
            project. Which are the things you need to renewal like these APIs
            and servers.
          </p>
          <p className="quotation-text-para py-1">
             You need to submit the required documents and contents if anything
            required from your side without fail of estimated time. if it will
            be delaying the project will be delay.
          </p>
          <p className="quotation-text-para py-1">
             New features will be considered as update modules and will incur
            an additional charge; they are not included under the Annual
            Maintenance Contract (AMC).
          </p>
          <p className="quotation-text-para">
            Thank you for considering our services. We look forward to the
            opportunity to work with you on this project.{" "}
          </p>
          <div className="quotaion-tq-text py-4">
            <span style={{ width: "27%", fontSize: "16px" }}>Thank you for your business!</span>
            <span style={{ width: "27%", fontSize: "16px" }}>D.v.kannan</span>
            <span style={{ width: "27%", fontSize: "16px" }}>CEO,2k Virtual world</span>
            <span style={{ width: "27%", fontSize: "16px" }}>kannan@2kvirtualworld.com</span>
            <span style={{ width: "27%", fontSize: "16px" }}>+91 8667079176</span>
          </div>
          <p className="quotation-text-content mt-4 p-0 mx-0 --url">
            <a href="www.2kvirtualworld.com" target="_blank">
              www.2kvirtualworld.com{" "}
            </a>
          </p>
        </div>
      </div>
    </>
  );
};

export default DownloadQuotation;
