import React from 'react'
import Images from '../../asstes'
import { useNavigate } from 'react-router'
import { useDispatch } from 'react-redux'
import { logout } from '../Redux/Actions'

export default function Navbar() {
  const navigation = useNavigate()
  const dispatch = useDispatch()
  const handlerLogout = () => {
    dispatch(logout());
    // window.location.reload();
  }
  return (

    <>
      <div className="d-flex  py-3 justify-content-end ml-3" style={{
        backgroundColor: " #FFF6EA"
      }} >
        <div style={{ paddingRight: 40, display: "flex", flexDirection: "row", gap: 15 }}>

          <img src={Images.notificationIcon} style={{ width: '100%', cursor: 'pointer' }} />
          <img src={Images.logoutIcon} style={{ width: '100%', cursor: 'pointer' }} onClick={() => handlerLogout()} />
        </div>

      </div>
      {/* <nav class="navbar navbar-expand-sm bg-light justify-content-end" style={{ height: "75px", width: "1440px" }}> */}
      {/* <ul class="navbar-nav">
    <li class="nav-item">
    
    
    </li>
    </ul> */}
      {/* </nav> */}
    </>
  )
}
